import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";
import { axiosInstance } from "../../services/apiServices/config";

//post login

export const postLoginData = createAsyncThunk(
	"login/postLoginData",
	(login, { rejectWithValue }) => {
		const logindata = apiServices
			.post("/login", login.data)
			.then((response) => {
				localStorage.setItem("token", response.access_token);
				return response;
			})

			.catch((err) => rejectWithValue(err.response.data));

		return logindata;
	}
);

// get current user
export const getCurrentUser = createAsyncThunk("login/getCurrentUser", (logout = () => {}) => {
	const logindata = apiServices
		.get("/get-current-user")
		.then((response) => {
			// if(response.request.status === 401){
			// logout()
			// }
			return response;
		})
		.catch((err) => console.log(err));

		logindata.then((res) => {
			if(res.response.status === 401){
				logout()
			}
		})
	return logindata;
});

//logout
export const LogoutFunc = createAsyncThunk("login/LogoutFunc", () => {
	const logoutData = apiServices
		.post("/logout")
		.then((response) => {
			localStorage.removeItem("persist:root");
			return response;
		})

		.catch((err) => console.log(err));
	return logoutData;
});

//sign up
export const signUpFunc = createAsyncThunk("login/signUpFunc", (signup) => {
	const signupData = axiosInstance
		.post("/register", signup.data)
		.then((res) => {
			message.success(res.data.message);
			localStorage.setItem("token", res.data.access_token);
			return res;
		})
		.catch((error) => {
			const errorMessage = error.response.data.message;
			const errorDetails = error.response.data.errors;
			if (errorDetails && errorDetails.email) {
				message.error(`${errorMessage} ${errorDetails.email.join(", ")}`);
			} else {
				message.error(errorMessage);
			}
			throw error
		});

	return signupData;
});

const initialState = {
	auth: false,
	token: null,
	loading: false,
	error: "",
	user: [],
	success: null,
	isLoggedIn: null,
	test: null,
};

const AuthSlice = createSlice({
	name: "auth",
	initialState,

	reducers: {
		AuthSeliceReset: (state) => {
			Object.assign(state, initialState);
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(postLoginData.pending, (state) => {
				state.loading = true;
				state.auth = false;
			})
			.addCase(postLoginData.fulfilled, (state, action) => {
				state.auth = true;
				state.loading = false;
				state.token = action.payload;
				state.user = [action.payload.user];
				state.isLoggedIn = true;
				state.success = true;
			})
			.addCase(postLoginData.rejected, (state, action) => {
				state.loading = true;
				state.auth = false;
				state.error = "One of the credentials are incorrect";
				state.isLoggedIn = false;
				state.success = false;
				// console.log("failed payload", action.payload);
				if (state.success === false) {
					state.loading = false;
					message.error("Invalid Credentials");
				}
			})

			// get current user
			.addCase(getCurrentUser.pending, (state) => {
				state.loading = true;
			})
			.addCase(getCurrentUser.fulfilled, (state, action) => {
				state.loading = false;
				state.user = [action.payload];
				state.token = action.payload;
			})
			.addCase(getCurrentUser.rejected, (state, action) => {
				state.loading = true;
				state.error = "No user found";
			})

			// logout

			.addCase(LogoutFunc.pending, (state) => {
				state.loading = true;
			})
			.addCase(LogoutFunc.fulfilled, (state, action) => {
				state.auth = false;
				state.loading = false;
				state.isLoggedIn = false;
			})
			.addCase(LogoutFunc.rejected, (state) => {
				state.loading = true;
				state.error = "failed to logout";
			})

			//register
			.addCase(signUpFunc.pending, (state) => {
				state.loading = true;
				state.auth = false;
			})
			.addCase(signUpFunc.fulfilled, (state, action) => {
				state.auth = true;
				state.loading = false;
				state.token = action.payload;
				state.user = [action.payload.user];
				state.isLoggedIn = true;
				state.success = true;
			})
			.addCase(signUpFunc.rejected, (state) => {
				state.loading = false;
				state.auth = false;
				state.error = "failed to sign up";
				state.success = false;
			});
	},
});

const AuthReducer = AuthSlice.reducer;

export const { AuthSeliceReset } = AuthSlice.actions;

export default AuthReducer;
