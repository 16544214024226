import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'antd';
import { getUsers } from '../../redux/Slice/UserSlice';
import { axiosInstance } from '../../services/apiServices/config';
import { getBookData } from '../../redux/Slice/BookSlice';

const useCreateNewBookDrawer = ({ open, close, onSubmit, drawerStatusProp }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [showGallery, setShowGallery] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({ email: '', visible: false });
  const [loading, setLoading] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { colors } = useSelector((state) => state.colors);
  const { seriesData } = useSelector((state) => state.series);
  const { book_data } = useSelector((state) => state.books);
  const { users } = useSelector((state) => state.user);

  const form = useRef();
  const [forms] = Form.useForm();

  const [bookList, setBookList] = useState({
    books: [],
  });

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setBookList((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleClose = useCallback(() => {
    close();
    forms.resetFields();
    setSelectedFile(null);
    setSelectedUsers([]);
    setCustomErrors({});
    setSubmissionError(null);
    setIsSubmitting(false);
  }, [close, forms]);

  const handleSubmit = useCallback(async (values) => {
    setLoading(true);
    setSubmissionError(null);
    setIsSubmitting(true);
    let data = { ...values };

    if (selectedFile) {
      data.image_id = selectedFile.id;
    } else if (drawerStatusProp !== 1) {
      data.image_id = null;
    }

    data.is_finished = values.is_finished ? 1 : 0;

    if (drawerStatusProp === 1) {
      data.books = bookList.books;
    }

    try {
      await onSubmit(data);
      handleClose();
      await dispatch(getBookData());
    } catch (error) {
      console.error("Failed to submit data", error);
      setSubmissionError(error.message || "Failed to submit data.");
    } finally {
      setLoading(false);
      setIsSubmitting(false);
      forms.resetFields();
    }
  }, [drawerStatusProp, selectedFile, bookList.books, onSubmit, handleClose, forms, dispatch]);

  useEffect(() => {
    if (drawerStatusProp !== 1 && selectedUsers.length > 0) {
      handleCollaborators();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerStatusProp, selectedUsers]);

  const handleCollaborators = useCallback(async () => {
    if (drawerStatusProp !== 1) {
      try {
        const collaborators = selectedUsers.map((user) => ({
          email: user.email,
          role: user.role,
        }));
        await axiosInstance.post("/collaborators", {
          collaborators,
          collaboratable_id: book_data[0]?.id,
          collaboratable_type: "books",
        });
      } catch (error) {
        console.error("Failed to add collaborator", error);
        setSubmissionError(error.message || "Failed to add collaborator.");
      }
    }
  }, [drawerStatusProp, selectedUsers, book_data]);

  const handleCollaboratorRole = useCallback((email) => {
    setCurrentUser({ email, visible: true });
  }, []);

  const closeCollaboratorRole = useCallback(() => {
    setCurrentUser({ email: '', role: '', visible: false });
  }, []);

  useEffect(() => {
    if (drawerStatusProp === 1) {
      setCustomErrors({});
    }
    if (users.length === 0) {
      dispatch(getUsers());
    }
  }, [drawerStatusProp, users, dispatch]);

  return {
    form,
    forms,
    selectedFile,
    setSelectedFile,
    showGallery,
    setShowGallery,
    customErrors,
    handleChange,
    handleClose,
    handleSubmit,
    handleCollaboratorRole,
    closeCollaboratorRole,
    currentUser,
    selectedUsers,
    setSelectedUsers,
    colors,
    seriesData,
    book_data,
    bookList,
    drawerStatusProp,
    users,
    loading,
    submissionError,
    isSubmitting,
  };
};

export { useCreateNewBookDrawer };