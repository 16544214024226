import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEventTypeList, getTimeline, postEventType } from "../../redux/Slice/TimelineSlice";
import { getOutlineList } from "../../redux/Slice/OutlineSlice";
import { hasEditorAccess } from "../../redux/Slice/SharedWork";

const useTimelineDetails = (props) => {
	const { mod } = props;
	const dispatch = useDispatch();
    const { timelines } = useSelector((state) => state.sharedWork);
	const { id } = useParams();
    const sharedId = new URLSearchParams(window.location.search).get('sharedId');
	const email = new URLSearchParams(window.location.search).get('email')
	const key = new URLSearchParams(window.location.search).get('key')
	const { timeline, events } = useSelector((state) => state.timeline);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openIndex, setOpenIndex] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [selectedEvent, setSelectedEvent] = useState("");
    const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [errorMessage, setErrorMessage] = useState(null);
	const name = timeline[0]?.data?.timeline?.name;
	const desc = timeline[0]?.data?.timeline?.description;

	console.log(errorMessage, 'errorMessage');
    let hasEditor = hasEditorAccess(timelines, id, sharedId);
	let geustData = {
		email,
		key
	}


	const AddEvent = (data) => {
		dispatch(postEventType({ data })).unwrap();
	};

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => setIsModalOpen(false);
	const handleCancel = () => setIsModalOpen(false);
	const close = () => setPopupVisible(false);
	const open = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};
	const handleOpenChange = (newOpen) => setPopupVisible(newOpen);

    const showEditorModal = () => {
		setIsEditorModalVisible(true);
	};

	const handleEditorModalOk = () => {
		setIsEditorModalVisible(false);
	};

	const handleEditorModalCancel = () => {
		setIsEditorModalVisible(false);
	};

	useEffect(() => {
		let wait = false;

		if (events.length > 0 && events[0].length > 0) {
			setSelectedEvent(events[0][events[0].length - 1].event_type);
		}
		if (!wait) {
			const fetchData = async () => {
				setLoading(true);
				await dispatch(getEventTypeList({id, mod, geustData}));
				await dispatch(getTimeline({id, mod, geustData, setErrorMessage}));
				setLoading(false);
				await dispatch(getOutlineList({id, mod, geustData}));
			};
			fetchData();
		}
		return () => {
			wait = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
        hasEditor,
		id,
		geustData,
		name,
		desc,
		events,
		isModalOpen,
		openIndex,
		popupVisible,
		selectedEvent,
		setSelectedEvent,
		showModal,
		handleOk,
		handleCancel,
		close,
		open,
		handleOpenChange,
		AddEvent,
        isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
		loading,
		errorMessage
	};
};

export { useTimelineDetails };