import React, { useContext, useRef, useState, useCallback } from "react";
import CustomButton from "../button/Button";
import Select from "react-select";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import { useParams } from "react-router-dom";
import BrainstormCard from "./BrainstormCard";
import { Pagination, Spin, message } from "antd";
import { HeaderDataContext } from "../../context/HeaderContext";
import AddBrainstormDrawer from "./AddBrainStormDrawer";
import EditBrainstormDrawer from "./EditBrainstormDrawer";
import PackageSubscriptionHook from "../PackageSubscriptionHook";
import { SubscriptionModal } from "../subscriptionodal";
import { useBrainstorms } from "../../hooks/brainstrom/useBrainstrom";
import { CommentsDrawer } from "../comments";
import { axiosInstance } from "../../services/apiServices/config";
import { useDispatch, useSelector } from "react-redux";
import { setBrainstormValues } from "../../redux/Slice/BrainStormSlice";
import FullPageSpinner from "../loader/FullPageSpinner";

const Brainstorms = () => {
	const token = localStorage.getItem("token");
	const header = useContext(HeaderDataContext);
	header.setHeading("Brainstorms");
	const { id } = useParams();
	const ref = useRef(null);

	const dispatch = useDispatch();
	const {
		books,
		brainstorms,
		totalRecords,
		currentPage,
		selectedBrainstorm,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		loading,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSubscriptionModal,
		setClickedButton,
		getBrainstormsByBook,
		handlePageClick,
		editBrainstorm,
		deleteBrainstorm,
		checkSubscription,
		openSubscriptionModal,
		closeSubscriptionModal, // Added to handle closing
	} = useBrainstorms(id, token);


	const BrainstormToDisplay = brainstorms; // Simplified based on the hook's management

	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({
		isOpen: false,
		id: null,
	});
	const [commentsState, setCommentsState] = useState({});
	const [newCommentState, setNewCommentState] = useState("");

	// Show Comment Drawer
	const showCommentDrawer = useCallback((comments, brainstormId) => {
		setCommentsState((prevState) => ({
			...prevState,
			[brainstormId]: comments.map((comment) => ({
				author: comment.author || "You",
				comment: comment.comment,
			})),
		}));
		setIsCommentDrawerOpen({ id: brainstormId, isOpen: true });
	}, []);

	// Close Comment Drawer
	const handleCommentDrawerClose = useCallback(() => {
		setIsCommentDrawerOpen({ isOpen: false, id: null });
	}, []);

	// Handle Adding a New Comment
	const handleAddComment = useCallback(async () => {
		if (newCommentState.trim()) {
			try {
				const response = await axiosInstance.post("/comments", {
					comment_type: "brainstorm",
					comment: newCommentState,
					brainstorm_id: isCommentDrawerOpen.id,
				});

				if (response.data) {
					const updatedBrainstorm = brainstorms.find(
						(brainstorm) => brainstorm.id === isCommentDrawerOpen.id
					);

					if (updatedBrainstorm) {
						const newComment = {
							id: updatedBrainstorm.comments.length + 1,
							author: "You",
							comment: newCommentState,
						};

						const updatedComments = [...updatedBrainstorm.comments, newComment];
						const updatedBrainstorms = brainstorms.map((brainstorm) =>
							brainstorm.id === isCommentDrawerOpen.id
								? { ...brainstorm, comments: updatedComments }
								: brainstorm
						);

						dispatch(setBrainstormValues(updatedBrainstorms));

						setCommentsState((prevState) => ({
							...prevState,
							[isCommentDrawerOpen.id]: [
								...prevState[isCommentDrawerOpen.id],
								newComment,
							],
						}));

						setNewCommentState("");
						message.success("Comment added successfully.");
					}
				}
			} catch (error) {
				message.error("Failed to add comment.");
				console.error("Error adding comment:", error);
			}
		} else {
			message.warning("Please enter a comment before submitting.");
		}
	}, [newCommentState, isCommentDrawerOpen.id, brainstorms, dispatch]);

	return (
		<div className="main-container">
			<PackageSubscriptionHook
				feature={["brainstorming-feature"]}
				ref={ref}
				title="Brainstorm"
				checkPackageSubscription={checkSubscription}
			/>
			<div className="list-actions brain-st-list">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle="Add Brainstorm"
						onClick={() =>
							controlledButtons.createBrainstorm
								? setShowAddNewDrawer(true)
								: (() => {
										setClickedButton([{ feature: "brainstorming-feature" }]);
										openSubscriptionModal();
								  })()
						}
						icon={<img src={CreateBookIcon} alt="New Brainstorm" />}
					/>
				</div>

				{books.length > 0 && (
					<div className="filter">
						<label>Select Book:</label>
						<Select
							options={[{ value: "", label: "-- Any Book --" }, ...books]}
							onChange={getBrainstormsByBook}
							isSearchable
							className="select-book"
							value={selectedBook}
							placeholder="Select a Book"
						/>
					</div>
				)}
			</div>

			<div className="card-container mt-2">
				<div className="p-3">
					{loading && brainstorms.length === 0 ? (
						<FullPageSpinner />
					) : brainstorms.length === 0 && !loading ? (
						<h4 style={{ textAlign: "center" }}>
							No brainstorms to display. Click on "Add Brainstorm" to add new.
						</h4>
					) : (
						brainstorms.map((brainstorm) => (
							<BrainstormCard
								key={brainstorm.id}
								brainstorm={brainstorm}
								showCommentDrawer={() =>
									showCommentDrawer(brainstorm.comments, brainstorm.id)
								}
								style={{
									backgroundColor: brainstorm.color_code,
									color: brainstorm.foreground_color,
									cursor: "pointer",
								}}
								editBrainstorm={() => editBrainstorm(brainstorm.id)}
								deleteBrainstorm={() => deleteBrainstorm(brainstorm.id)}
								showEditDrawer={showEditDrawer}
							/>
						))
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
					showSizeChanger={false}
					style={{ textAlign: "center", marginTop: "20px" }}
				/>
			</div>

			{/* Add Brainstorm Drawer */}
			<AddBrainstormDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getBrainstormsByBook={() => getBrainstormsByBook(selectedBook)}
			/>

			{/* Edit Brainstorm Drawer */}
			<EditBrainstormDrawer
				brainstorm={selectedBrainstorm}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getBrainstormsByBook={() => getBrainstormsByBook(selectedBook)}
			/>

			{/* Subscription Modal */}
			<SubscriptionModal
				open={subscriptionModal}
				ok={() => openSubscriptionModal()}
				features={clickedButton}
				cancel={() => closeSubscriptionModal()}
			/>

			{/* Comments Drawer */}
			<CommentsDrawer
				mod="author"
				hasEditor={true}
				isOpen={isCommentDrawerOpen.isOpen}
				onClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				onAddComment={handleAddComment}
			/>
		</div>
	);
};

export default Brainstorms;
