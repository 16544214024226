import React from "react";
import { useParams } from "react-router-dom";
import BrainstormDetails from "../../../components/brainstorm/BrainStormDetails";
import Timeline from "../../../components/timeline/TimelineDetails";
import PlotlineDetail from "../../../components/plotline/Plotline-detail";
import OutlineDetail from "../outline/OutlineDetail";
import BookDetails from "../Book-Details";

const GuestView = () => {
	const { type } = useParams();

	const renderComponent = () => {
		switch (type) {
			case "brainstorms":
				return <BrainstormDetails mod={"guest"} />;
			case "timelines":
				return <Timeline mod={"guest"} />;
			case "plot_planners":
				return <PlotlineDetail mod={"guest"} />;
			case "outlines":
				return <OutlineDetail mod={"guest"} />;
			case "book-detials":
				return <BookDetails mod={"guest"} />;
			default:
				return <div>Something went wrong!</div>;
		}
	};

	return (
		<>{type && <div className="main-container">{renderComponent()}</div>}</>
	);
};

export default GuestView;
