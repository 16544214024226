import React, { useState } from "react";
import { Popconfirm, Popover, Tag } from "antd";
import WebIcon from "../../assets/icons/web-icon.svg";
import "../cards/index.scss";
import { getTimeDuration } from "../../services/utilServices";
import newBook from "../../assets/images/newbook.png";
import { MenuButton } from "../button/MenuButton";
import { CommentOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const PlotlineCard = ({
	style,
	plotline,
	editPlotline,
	deletePlotline,
	isShared = false,
	shareId = null,
	showCommentDrawer,
}) => {
	const [showAction, setShowAction] = useState(false);

	console.log(plotline);
	return (
		<div className="book-card-container" id={plotline.id} style={style}>
			<div className="display-flex space-between plot-liner">
				<div>
					<ShareAltOutlined
						className="mr-1 align-center"
						style={{ color: plotline.foreground_color }}
					/>
					<span
						className="thin-text-13 mr-2"
						style={{ color: plotline.foreground_color }}
					>
						Shared by:
					</span>
					<img src={WebIcon} className="mr-1" alt="Web Icon" />
					<span
						className="bold-text-13"
						style={{ color: plotline.foreground_color }}
					>
						Shana Tenerths
					</span>
				</div>
				<div>
					<CommentOutlined
						style={{ color: plotline.foreground_color }}
						className="mr-2"
						onClick={showCommentDrawer}
					/>
					<Tag className="bold-white-text-11" onClick={showCommentDrawer}>
						{plotline.comments?.length ?? 0} Comment
					</Tag>
					<Popover
						popupVisible={false}
						placement="right"
						content={
							<>
								<div className="edit">
									<button
										onClick={() => {
											editPlotline(plotline.id);
											setShowAction(false);
										}}
										type="button"
									>
										Edit <i className="fa fa-check ml-2"></i>
									</button>
								</div>
								<div className="delete-btn">
									<Popconfirm
										title="Delete Plotline"
										description="Are you sure to delete this plotline?"
										onConfirm={() => deletePlotline(plotline.id)}
									>
										<button type="button">
											Delete <i className="fa fa-times ml-2"></i>
										</button>
									</Popconfirm>
								</div>
							</>
						}
						open={showAction}
						title="Actions"
						trigger="click"
						style={{ cursor: "pointer" }}
					>
						{!isShared && (
							<MenuButton
								onClick={() => setShowAction(!showAction)}
								id={plotline.id}
								color={plotline.foreground_color}
							/>
						)}
					</Popover>
				</div>
			</div>
			<Link
				to={`/plotline/${plotline.id}${isShared ? `?sharedId=${shareId}` : ""}`}
				style={{ color: plotline.foreground_color }}
			>
				<div className="display-flex mt-2 plot-flex-line">
					<Link
						to={`/plotline/${plotline.id}${
							isShared ? `?sharedId=${shareId}` : ""
						}`}
						style={{ color: plotline.foreground_color }}
					>
						<img
							src={plotline.image_url || newBook}
							className="mr-3 self-start"
							alt="Book or Series Icon"
							width="70"
							height="70"
						/>
					</Link>
					<div>
						<h2
							className="sub-title-16 font-w-800 mb-0-3"
							style={{ color: plotline.foreground_color }}
						>
							{plotline.plot_planner_title}
						</h2>
						<h4
							className="thin-text-13"
							style={{ lineHeight: 1.5, color: plotline.foreground_color }}
						>
							{plotline.description}
						</h4>
						<div className="display-flex mt-2">
							<p
								className="thin-text-13 mr-2"
								style={{ color: plotline.foreground_color }}
							>
								Updated: {getTimeDuration(plotline.updated)}
							</p>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default PlotlineCard;
