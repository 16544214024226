import "./index.scss";
import { CaretDownOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteCharacter, getCharacters, updateCharacter } from "../../redux/Slice/CharacterSlice";
import CharacterModal from "../../components/timeline/timeline-events/AddCharacterModal";
import EditCharacterModal from "../../components/timeline/timeline-events/edit/EditCharacterModal";
import { Popover, message } from "antd";
import { AddEventBlockModal, EditEventBlockModal } from "../../components/timeline/timeline-events/EventBlock/AddEventBlockModal";
import { getTimeline } from "../../redux/Slice/TimelineSlice";
import { updateEvBlock } from "../../redux/Slice/EventBlockSlice";
import debounce from 'lodash.debounce';

const Content = React.memo(({ c_id, path_id, close }) => {
	const [editModal, setEditModal] = useState(false);
	const dispatch = useDispatch();

	const showEditModal = () => setEditModal(true);
	const handleModalOk = () => setEditModal(false);
	const handleModalCancel = () => setEditModal(false);

	const deleteChar = async (cid) => {
		try {
			await dispatch(deleteCharacter(cid)).unwrap();
			close();
		} catch (error) {
			console.error("Failed to delete character:", error);
			message.error("Failed to delete character.");
		}
	};

	const editCharacter = async (data) => {
		try {
			const payload = { id: c_id, data };
			await dispatch(updateCharacter(payload)).unwrap();
			message.success("Character updated successfully.");
		} catch (error) {
			console.error("Failed to update character:", error);
			message.error("Failed to update character.");
		}
	};

	return (
		<div>
			<div className="edit">
				<button onClick={() => { showEditModal(); close(); }}>
					Edit
					<span className="ml-2">
						<i className="fa fa-check"></i>
					</span>
				</button>
			</div>
			<div className="delete-btn">
				<button onClick={() => deleteChar(c_id)}>
					Delete
					<span className="ml-2">
						<i className="fa fa-times"></i>
					</span>
				</button>
			</div>
			<EditCharacterModal
				isModalOpen={editModal}
				handleCancel={handleModalCancel}
				handleOk={handleModalOk}
				onSubmit={editCharacter}
				char_id={c_id}
			/>
		</div>
	);
});

const TimelineEvent = ({ selectedEvent, hasEditor, showEditorModal, mod, guestData }) => {
	const { id } = useParams();
	const [modalOpen, setIsModalOpen] = useState(false);
	const [eventModalOpen, setIsEventModalOpen] = useState(false);
	const [updateBlock, setUpdateBlock] = useState(false);
	const [openIndex, setOpenIndex] = useState(null);
	const [popupVisible, setPopupVisible] = useState(false);
	const [widths, setWidths] = useState({});
	const [stretching, setStretching] = useState(false);
	const [delta, setDelta] = useState(1);
	const [blockId, setBlockId] = useState(null);
	const [clicked, setClicked] = useState(false);
	const divRef = useRef(null);
	const dispatch = useDispatch();
	const { characters } = useSelector((state) => state.character);
	const { loading, timeline } = useSelector((state) => state.timeline);
	const [currentChar, setCurrentChar] = useState({});

	const showCharacterModal = () => setIsModalOpen(true);
	const handleCharacterModalOk = () => setIsModalOpen(false);
	const handleCharacterModalCancel = () => setIsModalOpen(false);

	const showEventModal = () => setIsEventModalOpen(true);
	const handleEventModalOk = () => setIsEventModalOpen(false);
	const handleEventModalCancel = () => setIsEventModalOpen(false);

	const showUpdateBlock = () => setUpdateBlock(true);
	const handleBlockModalOk = () => setUpdateBlock(false);
	const handleBlockModalCancel = () => setUpdateBlock(false);

	const charactersData = timeline[0]?.data?.timeline_characters || [];

	const handleMouseDown = (event, currentBlockId) => {
		setStretching(true);
		setDelta(event.clientX - (widths[currentBlockId] || 300));
		setBlockId(currentBlockId);
	};

	const handleMouseMove = useCallback((event) => {
		if (clicked && stretching && blockId) {
			setWidths((prevWidths) => ({
				...prevWidths,
				[blockId]: event.clientX - delta,
			}));
		}
	}, [clicked, stretching, blockId, delta]);

	const debouncedHandleUpdate = useCallback(debounce(async () => {
		if (blockId) {
			const block = charactersData.flatMap(c => c.blocks).find(b => b.id === blockId);
			if (block) {
				const newSize = widths[blockId] || block.size;
				const newPosition = delta;
				if (block.size !== newSize || block.position_x !== newPosition) {
					const payload = { id: blockId, data: { size: newSize, position_x: newPosition } };
					try {
						console.log("payload", payload);
						await dispatch(updateEvBlock(payload)).unwrap();
						await dispatch(getTimeline({ id })).unwrap();
					} catch (error) {
						console.error("Failed to update block:", error);
						message.error("Can't update block size.");
					}
				} else {
					message.info("No changes detected.");
				}
			}
			setStretching(false);
			setClicked(false);
		}
	}, 300), [blockId, charactersData, delta, dispatch, id, widths]);

	const handleMouseUp = useCallback(() => {
		
		if (stretching) {
			setStretching(false);
			setClicked(false);
			console.log("clicked");
			debouncedHandleUpdate();
		}
	}, [stretching, debouncedHandleUpdate]);

	useEffect(() => {
		window.addEventListener('mousemove', handleMouseMove);
		window.addEventListener('mouseup', handleMouseUp);
		return () => {
			window.removeEventListener('mousemove', handleMouseMove);
			window.removeEventListener('mouseup', handleMouseUp);
		};
	}, [handleMouseMove, handleMouseUp]);

	const closePopup = () => setPopupVisible(false);
	const openPopup = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};
	const handleOpenChange = (newOpen) => setPopupVisible(newOpen);

	useEffect(() => {
		if (!loading) {
			dispatch(getCharacters({ id, mod, guestData }));
		}
	}, [dispatch, id, mod, guestData, loading]);

	return (
		<>
			<div className="eventDiv">
				<div className="characterBlock" style={{ maxHeight: "200px" }}>
					<div style={{ position: "relative", bottom: "20px" }}>
						{mod !== 'guest' && (
							<button className="newEventbtn" onClick={hasEditor ? showEventModal : showEditorModal}>
								<PlusOutlined className="mr-1 plusIcon" />
								<span>Add Event Block</span>
							</button>
						)}
					</div>
					{characters?.length > 0 && characters.map((c) => (
						<div key={c.id}>
							<div>
								{hasEditor ? (
									<Popover
										placement="rightBottom"
										content={<Content c_id={c.id} path_id={id} close={closePopup} />}
										title="Actions"
										trigger="click"
										style={{ cursor: "pointer" }}
										open={c.id === openIndex && popupVisible}
										onOpenChange={handleOpenChange}
									>
										<button className="charName mr-2" onClick={() => openPopup(c.id)}>
											<CaretDownOutlined className="caretIcon" />
											<span>{c.character_name}</span>
										</button>
									</Popover>
								) : (
									<button className="charName mr-2" onClick={showEditorModal}>
										<CaretDownOutlined className="caretIcon" />
										<span>{c.character_name}</span>
									</button>
								)}
								<div className="block mt-2 mb-2">
									{charactersData.map((ch) => (
										ch.blocks?.length > 0 && ch.blocks.map((block) => (
											block.character_id === c.id && (
												<div style={{ height: "90px", userSelect: "none" }} key={block.id}>
													<div
														style={{
															position: "absolute",
															backgroundColor: block.color_code,
															width: `${widths[block.id] || block.size || 300}px`,
															cursor: hasEditor ? "se-resize" : "pointer",
														}}
														onMouseDown={hasEditor ? (e) => handleMouseDown(e, block.id) : null}
														onClick={hasEditor ? () => {
															setBlockId(block.id);
															setClicked(true);
														} : showEditorModal}
														onDoubleClick={hasEditor ? (e) => handleMouseUp(e, block.id) : null}
														className="event-block"
													>
														<div className="event-detail">
															<p>{block.event_name}</p>
															<p>{block.event_description}</p>
														</div>
														<div className="event-outline">
															{block.outline}
														</div>
													</div>
												</div>
											)
										))
									))}
								</div>
							</div>
						</div>
					))}
					<div className="newCharBtn">
						{mod !== 'guest' && (
							<button onClick={hasEditor ? showCharacterModal : showEditorModal} className="mt-3 newChar">
								<PlusOutlined className="mr-1 plusIcon" />
								New Character
							</button>
						)}
					</div>
				</div>
			</div>

			<CharacterModal
				isModalOpen={modalOpen}
				handleCancel={handleCharacterModalCancel}
				handleOk={handleCharacterModalOk}
				isUpdate={false}
			/>
			<AddEventBlockModal
				handleOk={handleEventModalOk}
				handleCancel={handleEventModalCancel}
				isModalOpen={eventModalOpen}
			/>
			<EditEventBlockModal
				handleOk={handleBlockModalOk}
				handleCancel={handleBlockModalCancel}
				isModalOpen={updateBlock}
			/>
		</>
	);
};

export default React.memo(TimelineEvent);
