import React, { useState } from "react";
import CustomDrawer from "../../../components/drawer";
import "./getHelp.scss";
import {
	CloseOutlined,
	MailOutlined,
	CloudUploadOutlined,
} from "@ant-design/icons";
import {
	Select,
	Input,
	Row,
	Col,
	Divider,
	Button,
	message,
	Upload,
	Form,
	Spin,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const { Dragger } = Upload;
const { Option } = Select;
const { TextArea } = Input;

const GetHelp = () => {
	const navigate = useNavigate();
	const [showDrawer, setShowDrawer] = useState(true);
	const [form] = Form.useForm();
	const [fileList, setFileList] = useState([]);
	const [submitting, setSubmitting] = useState(false);

	const handleSubmit = async (values) => {
		setSubmitting(true);
		const formData = new FormData();
		formData.append('first_name', values.firstName);
		formData.append('last_name', values.lastName);
		formData.append('description', values.description);
		formData.append('email', values.email);
		formData.append('reason', values.reason);
		formData.append('level', values.urgency);
		if (fileList.length > 0) {
			fileList.forEach(file => {
				formData.append('file', file);
			});
		}

		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/get-help`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			});
			if (response.status === 201) {
				message.success("Support ticket submitted successfully.");
				form.resetFields();
				setFileList([]);
				navigate('/dashboard');
			} else {
				message.error("Failed to submit support ticket.");
			}
		} catch (error) {
			if (error.response && error.response.data && error.response.data.errors) {
				const errors = error.response.data.errors;
				if (errors.file) {
					errors.file.forEach(err => {
						message.error(err);
					});
				} else {
					message.error("An error occurred while submitting the support ticket.");
				}
			} else {
				message.error("An error occurred while submitting the support ticket.");
			}
		} finally {
			setSubmitting(false);
		}
	};

	const uploadProps = {
		name: "file",
		multiple: true,
		beforeUpload: (file) => {
			setFileList([...fileList, file]);
			return false;
		},
		onRemove: (file) => {
			setFileList(fileList.filter(item => item.uid !== file.uid));
		},
	};

	const getDrawerContent = () => (
		<Form form={form} onFinish={handleSubmit}>
			<div className="mb-2">
				<div className="d-flex">
					<div className="button-active"></div>
					<h2>Submit a Support Ticket</h2>
					<CloseOutlined className="close" onClick={() => {setShowDrawer(!showDrawer); form.resetFields(); setFileList([]); navigate('/dashboard')}} />
				</div>
				<br />
				<p className="para">
					Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris non quam fentum, consequat quam ac, mattis ex.
				</p>
				<br />
				<label className="label">Which best describes your reason for reaching out?</label>
				<Form.Item
					name="reason"
					initialValue="Sales"
					rules={[{ required: true, message: 'Please select a reason' }]}
				>
					<Select style={{ width: "100%" }}>
						<Option value="Sales">Sales</Option>
						<Option value="Company">Company</Option>
					</Select>
				</Form.Item>
				<br />
				<br />
				<label className="label">What is your name ?</label>
				<Input.Group className="mt-1 mb-4">
					<Row gutter={24}>
						<Col span={12}>
							<Form.Item
								name="firstName"
								rules={[{ required: true, message: 'Please enter your first name' }]}
							>
								<Input prefix="First:" />
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="lastName"
								rules={[{ required: true, message: 'Please enter your last name' }]}
							>
								<Input prefix="Last:" />
							</Form.Item>
						</Col>
					</Row>
				</Input.Group>
				<label className="label">What is your email ?</label>
				<Form.Item
					name="email"
					rules={[
						{ required: true, message: 'Please enter your email' },
						{ type: 'email', message: 'Please enter a valid email' }
					]}
				>
					<Input
						className="mb-4 mt-1"
						prefix={<MailOutlined />}
						placeholder="youremail@domain.com"
					/>
				</Form.Item>
				<label className="label"> What is the level of urgency?</label>
				<Form.Item
					name="urgency"
					initialValue="average"
					rules={[{ required: true, message: 'Please select the level of urgency' }]}
				>
					<Select style={{ width: "100%" }} className="select_getHelp">
						<Option value="average">Average</Option>
						<Option value="below">Below</Option>
						<Option value="low">Low</Option>
					</Select>
				</Form.Item>
				<label style={{ fontSize: "12px", color: "#202127" }}>
					Falsely attributing high urgency may affect your support priority.
				</label>
				<br />
				<br />
				<label className="label">Describe your issue</label>
				<Form.Item
					name="description"
					rules={[{ required: true, message: 'Please describe your issue' }]}
				>
					<TextArea
						className="text mb-4 mt-1"
						rows={4}
						placeholder="Please include any screenshots below if relevant"
						maxLength={500}
					/>
				</Form.Item>
				<label className="label">Click or Drag Files to Upload </label>
				<Dragger {...uploadProps} className="mt-1 mb-1 upload">
					<p className="ant-upload-drag-icon">
						<CloudUploadOutlined style={{ color: "rgba(139, 139, 139, 0.3)" }} />
					</p>
				</Dragger>
				<label className="upload-label">
					This is a description of the upload requirements.
				</label>
				<Divider />
				<Row gutter={24}>
					<Col span={12}>
						<Button className="change" type="primary" size="large" onClick={() => form.submit()} disabled={submitting}>
							{ submitting ? <Spin /> : "Submit" }
						</Button>
					</Col>
					<Col span={12}>
						<Button className="remove" type="outlined" size="large" onClick={() => {setShowDrawer(false); form.resetFields(); setFileList([]); navigate('/dashboard')}}>
							Cancel
						</Button>
					</Col>
				</Row>
			</div>
		</Form>
	);

	return (
		<div style={{ position: "relative" }} id="chat-main">
			<CustomDrawer
				getContainer={() => document.getElementById("chat-main")}
				className="chat-drawer"
				closable={false}
				open={showDrawer}
				placement="right"
			>
				{getDrawerContent()}
			</CustomDrawer>
		</div>
	);
};

export default GetHelp;
