import React from "react";
import { AudioVisualizer } from "react-audio-visualize";
import { Col, Row, Input, Button, Form } from "antd";
const AddNewRound = ({
	refRoundTitle,
	disableInput,
	error,
	bsTranscript,
	setBSTranscript,
	recordBlob,
	saveRound,
	roundLoading,
}) => {
	const [form] = Form.useForm();

	const onFinish = (values) => {
		saveRound(values);
	};
	return (
		<Row>
			<Form form={form} onFinish={onFinish} className="w-100">
				<Col sm={24} className="mt-3">
					<label htmlFor="title" className="ts-heading">
					Title
				</label>
				<Form.Item name="title" rules={[{ required: true, message: 'Please input the title' }]}>
					<Input
						id="title"
						ref={refRoundTitle}
						className={error.title && "ant-input-status-error"}
						disabled={disableInput}
				/>
				{error.title && (
						<p className="ant-form-item-explain-error mb-2">{error.title}</p>
					)}
				</Form.Item>
			</Col>
			<Col sm={24} className="mt-3">
				<label htmlFor="transcript" className="ts-heading">
					Transcript
				</label>
				<Input.TextArea
					id="transcript"
					value={bsTranscript}
					onChange={(e) => setBSTranscript(e.target.value)}
					rows={4}
					showCount={true}
					maxLength={1024}
					style={{ resize: "none" }}
					className={error.title && "ant-input-status-error"}
					disabled={disableInput}
				></Input.TextArea>
				{error.transcript && (
					<p className="ant-form-item-explain-error mb-2">{error.transcript}</p>
				)}
			</Col>
			{recordBlob && (
				<Col sm={24} className="mt-3">
					<AudioVisualizer
						blob={recordBlob}
						width={900}
						height={75}
						barWidth={3}
						gap={2}
						barColor="#2DB6F5"
					/>
				</Col>
			)}
			<Col sm={24} className="mt-3">
				<Button
					type="primary"
					htmlType="submit"
					disabled={disableInput}
					loading={roundLoading}
				>
					Save Round
				</Button>
				</Col>
			</Form>
		</Row>
	);
};

export default AddNewRound;
