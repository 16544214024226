import {
	Row,
	Col,
	Card,
	Typography,
	Avatar,
	Tabs,
	Pagination,
	Alert,
} from "antd";
import React, { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import FullPageSpinner from "../../components/loader/FullPageSpinner";
import { DashboardActivityLog } from "../../components/cards/DashboardActivityLog";
import { axiosInstance } from "../../services/apiServices/config";
import { useDispatch, useSelector } from "react-redux";
import { getSharedSpecific } from "../../redux/Slice/SharedWork";
import OutlineCard from "../../components/outlines/OutlineCard";
import PlotlineCard from "../../components/plotline/PlotlineCard";
import BrainstormCard from "../../components/brainstorm/BrainstormCard";
import TimelineCard from "../../components/timeline/TimelineCard";

const { Title, Text } = Typography;
const { TabPane } = Tabs;
const ITEMS_PER_PAGE = 5;

const Section = ({
	title,
	items,
	CardComponent,
	currentPage,
	onPageChange,
	shareId,
}) => {
	const dispatch = useDispatch();
	const [error, setError] = useState(null);

	return (
		<div className="section">
			<Title level={3} className="section-title">
				{title}
			</Title>
			{error && (
				<Alert message="Error" description={error} type="error" showIcon />
			)}
			{items?.length > 0 ? (
				items.map((item, index) => (
					<Row key={item?.id || index} gutter={[16, 16]} className="mb-2">
						<Col xs={24}>
							<Card
								hoverable
								className="item-card"
								style={{
									backgroundColor: item.color_code,
									color: item.foreground_color,
								}}
							>
								<CardComponent
									{...{ [title.toLowerCase().slice(0, -1)]: item }}
									plotline={item}
									isShared={true}
									shareId={shareId}
								/>
							</Card>
						</Col>
					</Row>
				))
			) : (
				<h5>No {title.toLowerCase()} found</h5>
			)}
			<Pagination
				style={{ float: "right" }}
				current={currentPage}
				pageSize={ITEMS_PER_PAGE}
				total={items?.length}
				onChange={onPageChange}
				className="pagination"
			/>
		</div>
	);
};

const CountDisplay = ({ title, count, collaborators, type }) => (
	<Card
		className="count-display"
		bordered={false}
		style={{ textAlign: "left", padding: "12px", marginBottom: "16px" }}
	>
		<Title level={4} className="count-title" style={{ marginBottom: "4px" }}>
			{title}
		</Title>
		<Text
			className="count-text"
			style={{ display: "block", marginBottom: "4px" }}
		>
			{type} Count: {count}
		</Text>
		<Text
			className="count-text"
			style={{ display: "block", marginBottom: "8px" }}
		>
			Users: {collaborators.length}
		</Text>
		<div
			className="collaborators"
			style={{ display: "flex", gap: "4px", flexWrap: "wrap" }}
		>
			{collaborators.slice(0, 5).map((collaborator, index) => (
				<Avatar
					key={index}
					src={collaborator.profile_image_url || null}
					alt={collaborator.name}
					icon={!collaborator.profile_image_url ? <UserOutlined /> : null}
					className="collaborator-avatar"
					size="small"
				/>
			))}
			{collaborators.length > 5 && (
				<Avatar className="collaborator-avatar" size="small">
					+
				</Avatar>
			)}
		</div>
	</Card>
);

const BookDetails = (props) => {
	const { mod = "auther" } = props;
	const dispatch = useDispatch();
	const { sharedWorks, outlines, plotlines, brainStorms, timelines, error } =
		useSelector((state) => state.sharedWork);
	const [selectedSection, setSelectedSection] = useState("All");
	const [currentPage, setCurrentPage] = useState(1);
	const [loading, setLoading] = useState(true);
	const [activityLoading, setActivityLoading] = useState(false);
	const [totalPages, setTotalPages] = useState(1);
	const [sharedBookActivity, setSharedBokActivity] = useState({ data: [] });
	const [isEditor, setIsEditor] = useState(null);
	const [collaboratorsData, setCollaboratorsData] = useState({
		brainstorms: { users: [], count: 0 },
		outlines: { users: [], count: 0 },
		timelines: { users: [], count: 0 },
		plot_planners: { users: [], count: 0 },
	});
	const [errorMessage, setErrorMessage] = useState(null);

	let bookId = window.location.pathname.split("/").pop();
	const sharedId = new URLSearchParams(window.location.search).get("sharedId");
	const email = new URLSearchParams(window.location.search).get("email");
	const key = new URLSearchParams(window.location.search).get("key");
	let geustData = {
		email,
		key,
	};

	const handleSectionChange = (key) => {
		setSelectedSection(key);
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		const fetchActivity = async () => {
			setActivityLoading(true);
			try {
				const response = await axiosInstance.get(
					`/logs/books/${bookId}?page=${currentPage}${
						mod === "guest"
							? `&email=${geustData.email}&key=${geustData.key}`
							: ""
					}`
				);
				setSharedBokActivity(response.data);
				setTotalPages(response.meta.last_page);
			} catch (err) {
				console.error(err.message);
			} finally {
				setActivityLoading(false);
			}
		};
		fetchActivity();
	}, [currentPage, mod]);

	useEffect(() => {
		const fetchActivity = async () => {
			setActivityLoading(true);
			try {
				const response = await axiosInstance.get(
					`/logs/books/${bookId}?page=${currentPage}${
						mod === "guest"
							? `&email=${geustData.email}&key=${geustData.key}`
							: ""
					}`
				);
				console.log(response, 'response');
				setSharedBokActivity(response.data);
				setTotalPages(response.meta?.last_page);
			} catch (err) {
				if (err.response && err.response.status === 404) {
					setErrorMessage("Book not found");
				} else if (err.response && err.response.status === 401) {
					setErrorMessage("Unauthorized");
				} else {
					setErrorMessage("An error occurred");
				}
				console.error(err);
			} finally {
				setLoading(false);
				setActivityLoading(false);
			}
		};
		const fetchCollaboratorsData = async () => {
			try {
				const response = await axiosInstance.get(
					`/collaborators/shared/specific/users?book_id=${bookId}${
						mod === "guest"
							? `&email=${geustData.email}&key=${geustData.key}`
							: ""
					}`
				);
				setCollaboratorsData(response.data);
			} catch (err) {
				console.error(err.message);
			}
		};
		const checkIsEditor = () => {
			const editorStatus = true;
			setIsEditor(editorStatus);
		};
		fetchActivity();
		fetchCollaboratorsData();
		checkIsEditor();
	}, [bookId, mod]);

	useEffect(() => {
		const fetchSharedSpecific = async () => {
			if (selectedSection !== "All" && bookId) {
				try {
					await dispatch(
						getSharedSpecific({
							type: selectedSection,
							collaborator_id: bookId,
							page: currentPage,
							mod,
							geustData,
						})
					).unwrap();
				} catch (err) {
					console.error(err.message);
				}
			}
		};
		fetchSharedSpecific();
	}, [selectedSection, mod]);

	const renderContent = () => {
		switch (selectedSection) {
			case "outlines":
				return (
					<>
						<CountDisplay
							title="Outlines"
							type="Outline"
							count={collaboratorsData.outlines.count}
							collaborators={collaboratorsData.outlines.users}
						/>
						<Section
							title="Outlines"
							items={outlines[bookId]?.data ?? []}
							CardComponent={OutlineCard}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							shareId={bookId}
						/>
					</>
				);
			case "plot_planners":
				return (
					<>
						<CountDisplay
							title="Plotlines"
							type="Plotline"
							count={collaboratorsData.plot_planners.count}
							collaborators={collaboratorsData.plot_planners.users}
						/>
						<Section
							title="Plotlines"
							items={plotlines[bookId]?.data ?? []}
							CardComponent={PlotlineCard}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							shareId={bookId}
						/>
					</>
				);
			case "brainstorms":
				return (
					<>
						<CountDisplay
							title="Brainstorms"
							type="Brainstorm"
							count={collaboratorsData.brainstorms.count}
							collaborators={collaboratorsData.brainstorms.users}
						/>
						<Section
							title="Brainstorms"
							items={brainStorms[bookId]?.data ?? []}
							CardComponent={BrainstormCard}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							shareId={bookId}
						/>
					</>
				);
			case "timelines":
				return (
					<>
						<CountDisplay
							title="Timelines"
							type="Timeline"
							count={collaboratorsData.timelines.count}
							collaborators={collaboratorsData.timelines.users}
						/>
						<Section
							title="Timelines"
							items={timelines[bookId]?.data ?? []}
							CardComponent={TimelineCard}
							currentPage={currentPage}
							onPageChange={handlePageChange}
							shareId={bookId}
						/>
					</>
				);
			default:
				return (
					<Row gutter={[16, 16]}>
						<Col span={6}>
							<CountDisplay
								title="Outlines"
								type="Outline"
								count={collaboratorsData.outlines.count}
								collaborators={collaboratorsData.outlines.users}
							/>
						</Col>
						<Col span={6}>
							<CountDisplay
								title="Plotlines"
								type="Plotline"
								count={collaboratorsData.plot_planners.count}
								collaborators={collaboratorsData.plot_planners.users}
							/>
						</Col>
						<Col span={6}>
							<CountDisplay
								title="Brainstorms"
								type="Brainstorm"
								count={collaboratorsData.brainstorms.count}
								collaborators={collaboratorsData.brainstorms.users}
							/>
						</Col>
						<Col span={6}>
							<CountDisplay
								title="Timelines"
								type="Timeline"
								count={collaboratorsData.timelines.count}
								collaborators={collaboratorsData.timelines.users}
							/>
						</Col>
					</Row>
				);
		}
	};

	if(loading) {
		return <FullPageSpinner />;
	}
	if (errorMessage) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%' }}>
				<Card 
					style={{ 
						padding: '30px', 
						border: '1px solid #f5c6cb', 
						borderRadius: '12px', 
						backgroundColor: '#f8d7da', 
						width: '50%',
						boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
					}}
				>
					<p style={{ 
						color: '#721c24', 
						fontSize: '20px', 
						marginBottom: '15px', 
						fontWeight: 'bold' 
					}}>
						{errorMessage} !
					</p>
					<Alert 
						message="Error" 
						description={errorMessage} 
						type="error" 
						showIcon 
						style={{ marginTop: '10px' }} 
					/>
				</Card>
			</div>
		);
	}

	return (
		<div className="main-container mb-5" id="book-det-pg">
			<div className="display-flex">
				<div className="screen-title dark-black mr-3">Book Detail</div>
			</div>
			<Tabs
				activeKey={selectedSection}
				onChange={handleSectionChange}
				className="tabs-container"
			>
				{mod !== "guest" && <TabPane tab="All" key="All" />}
				{mod !== "guest" && <TabPane tab="Outlines" key="outlines" />}
				{mod !== "guest" && <TabPane tab="Plotlines" key="plot_planners" />}
				{mod !== "guest" && <TabPane tab="Brainstorms" key="brainstorms" />}
				{mod !== "guest" && <TabPane tab="Timelines" key="timelines" />}
			</Tabs>
			{loading ? (
				<FullPageSpinner />
			) : (
				<>
					{renderContent()}
					{selectedSection === "All" && (
						<div className="activity-log-section pt-3">
							<Title level={3} className="section-title">
								Activity Log
							</Title>
							{sharedBookActivity.data.length > 0 ? (
								<>
									<div className="activity-log-list">
										{sharedBookActivity.data.map((item, index) => (
											<DashboardActivityLog logsData={item} key={index} />
										))}
									</div>
									<Pagination
										current={currentPage}
										pageSize={10}
										total={totalPages * 10}
										onChange={handlePageChange}
										showSizeChanger={false}
										disabled={activityLoading}
										className="activity-pagination"
										style={{ float: "right", paddingTop: "10px" }}
									/>
								</>
							) : (
								!activityLoading && (
									<div className="no-activity-message">
										<p>No activity logs available.</p>
									</div>
								)
							)}
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default BookDetails;
