import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";

//post
export const postOutlineData = createAsyncThunk(
	"outline/postOutlineData",
	(outline) => {
		const outlineData = apiServices
			.post(`/outlines`, outline.data)
			.then((response) => {
				return response;
			})

			.catch((err) => console.log(err));
		return outlineData;
	}
);

//get outline data
export const getOutlineList = createAsyncThunk(
	"outline/getOutlineList",
	({id, mod, geustData}) => {
		const outlineData = apiServices
			.get(`/outlines-list${id ? "/" + id : ""}${mod === 'guest' ? `?email=${geustData.email}&key=${geustData.key}` : ''}`)
			.then((response) => response)
			.catch((err) => err);
		return outlineData;
	}
);
export const getOutline = createAsyncThunk("outline/getOutlineLine", ({id, mod, guestData}) => {
	const outlineData = apiServices
		.get(`/outlines/${id}${mod === 'guest' ? `?email=${guestData.email}&key=${guestData.key}` : ''}`, mod)
		.then((response) => response)
		.then((data) => data);
	return outlineData;
});

export const updateOutline = createAsyncThunk(
	"outline/updateOutline",
	({ id, data }) => {
		const outline = apiServices
			.update(`/outlines/${id}`, data)
			.then((response) => {
				return response;
			});

		return outline;
	}
);
export const deleteOutline = createAsyncThunk(
	"outline/deleteOutline",
	async ({ id }) => {
		await apiServices.delete(`/outlines/${id}`).then((response) => response);

		return { id };
	}
);
const initialState = {
	outlineData: [],

	loading: false,
	error: false,
	outline: [],
};

const OutlineSlice = createSlice({
	name: "outline",
	initialState,
	reducers: {
		outlineSeliceReset : (state) => {
			Object.assign(state, initialState);
		},
		setOutlinesBySeliece : (state, action) => {
			state.outlineData = action.payload
		},
	},
	extraReducers: (builder) => {

		builder.addCase(getOutlineList.pending, (state) => {
			state.loading = true;
		})
		.addCase(getOutlineList.fulfilled, (state, action) => {
			state.loading = false;
			state.outlineData = Array.isArray(action?.payload?.data) ? [...action.payload.data] : [];
		})
		.addCase(getOutlineList.rejected, (state, action) => {
			state.loading = false;
			state.error = [action.payload];
		})
		.addCase(getOutline.pending, (state) => {
			state.loading = true;
		})
		.addCase(getOutline.fulfilled, (state, action) => {
			state.loading = false;
			state.outline = [action.payload];
		})
		.addCase(getOutline.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
		})
		.addCase(postOutlineData.pending, (state) => {
			state.loading = true;
		})
		.addCase(postOutlineData.fulfilled, (state, action) => {
			state.loading = false;
			state.outlineData = [action.payload];
			message.success("Outline created successfully");
		})
		.addCase(postOutlineData.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			message.success("Outline not created");
		})
		.addCase(updateOutline.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateOutline.fulfilled, (state, action) => {
			state.loading = false;
			state.outlineData = [action.payload];
			message.success("outline updated successfully");
		})
		.addCase(updateOutline.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Outline not updated");
		})
		.addCase(deleteOutline.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteOutline.fulfilled, (state, action) => {
			state.loading = false;
			state.outlineData = action.payload;
			message.success("Outline deleted successfully");
		})
		.addCase(deleteOutline.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Outline not deleted");
		})
	},
});

const OutlineReducer = OutlineSlice.reducer;
export const { outlineSeliceReset, setOutlinesBySeliece } = OutlineSlice.actions;

export default OutlineReducer;
