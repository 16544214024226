import { useContext, useEffect, useRef, useState, useCallback } from "react";
import { HeaderDataContext } from "../../context/HeaderContext";
import { useDispatch } from "react-redux";
import useDebounce from "../useDebounce";
import { axiosInstance } from "../../services/apiServices/config";
import { populateFeatures } from "../../pages/screen/NewChat/helper";
import { ChatDataContext } from "../../context/ChatContext";
import { getContacts, messageSeen } from "../../redux/Slice/ChatSlice";
import { notification } from "antd";

// Hook for managing chat area functionalities
const useChatArea = (props) => {
    const {
        gotoPreviousPage,
        handleTyping,
        editMessage,
        handleEdit,
        sendMessage,
        message,
        messages,
        chatId,
        temp,
        setTemp,
        setScrollType,
        setMessages,
        attachedFiles,
        setAttachedFiles,
        setSearchedMessages,
        setSocketConnected
    } = props;

    // Context and Refs
    const header = useContext(HeaderDataContext);
    const chatContext = useContext(ChatDataContext);
    const messageEl = useRef(null);

    // State initializations
    const dispatch = useDispatch();
    const [chat, setChat] = useState("");
    const [chatFeature, setChatFeature] = useState("");
    const [searchText, setSearchText] = useState("");
    const searchValue = useDebounce(searchText, 100);
    const [searchActive, setSearchActive] = useState(false);
    const [features, setFeatures] = useState([]);
    const [repliedMessage, setRepliedMessage] = useState('');
    const [editMessages, setEditMessages] = useState('');
    const [editedMessages, setEditedMessages] = useState([]);
    const [messageMenu, setMessageMenu] = useState(false);
    const [scrollPageNated, setScrollPageNated] = useState(false);
    const [repliedImage, setRepliedImage] = useState('');
    const [deletedId, setDeletedId] = useState([]);
    const [showImageModal, setShowImageModal] = useState({
        isShow: false,
        id: null,
    });
    const [imagesOnModal, setImagesOnModal] = useState([]);

    // Debugging logs
    console.log(attachedFiles.length, 'attachedFile length')
    console.log(chat, 'messages writing...')

    // Feature search functionality
    const searchFeatures = useCallback(async (s) => {
        try {
            const response = await axiosInstance.get(
                `/search-chat-items/${chatFeature}?query=${s}`
            );
            setFeatures(
                populateFeatures(chatFeature, response.data.data, setChatFeature, chat, setChat)
            );
        } catch (error) {
            console.log(error);
        }
    }, [chatFeature, chat]);

    // Command handling
    const handleCommand = useCallback((command) => {
        setChatFeature(command);
    }, []);

    // Scroll handling
    const handleScroll = useCallback(async () => {
        setScrollPageNated(false);
        gotoPreviousPage();
    }, [gotoPreviousPage]);

    // Message input handling
    const handleMessageChange = useCallback((e) => {
        const { value } = e.target;
        setChat(value);
        if (value.includes('/')) {
            const command = value.match(/\/(\w+)/)[1];
            switch (command) {
                case "timeline":
                    return setChatFeature("timeline");
                case "brainstorm":
                    return setChatFeature("brainstorm");
                case "outline":
                    return setChatFeature("outline");
                case "plot":
                    return setChatFeature("plot");
                case "book":
                    return setChatFeature("book");
                case "series":
                    return setChatFeature("series");
                default:
                    break;
            }
        }
        handleTyping();
    }, [handleTyping]);

    // Send message handling
    const handleSend = useCallback(async () => {
        setScrollPageNated(false);
        if (editMessages) {
            const res = await editMessage({ id: editMessages, message: chat });
            if (res) {
                await handleEdit(editMessages, chat);
                setTemp({ id: editMessages, type: 'edit', message: chat });
                setChat("");
                setEditMessages('');
            }
            return;
        }
        if (sendMessage(chat, features, repliedMessage.parentId, attachedFiles) && !editMessages) {
            setChat("");
            setRepliedMessage('');
        }
    }, [editMessages, chat, handleEdit, setTemp, sendMessage, features, repliedMessage, attachedFiles]);

    // Toggle search
    const toggleSearch = useCallback(() => {
        setSearchActive(!searchActive);
        setSearchedMessages([]);
    }, [searchActive, setSearchedMessages]);

    // Attachment handling
    const showAttachments = useCallback(() => {
        document.getElementById("attachments").click();
    }, []);

    const hadleImagesModal = useCallback((id) => {
        setShowImageModal((prev) => ({
            isShow: !prev.isShow,
            id: id ? id : null
        }));
    }, []);

    const handleAttachments = useCallback((e) => {
        setAttachedFiles([]);
        const files = e.target.files;
        if (files) {
            const maxFiles = 12;
            if (files.length > maxFiles) {
                notification.open({
                    message: `You can only send ${maxFiles} files at a time`,
                });
                setAttachedFiles([]);
            } else {
                const filesAttached = Array.from(files);
                setAttachedFiles(filesAttached);
            }
        }
    }, [setAttachedFiles]);

    console.log(attachedFiles, 'attachedFiles log');

    // Effects for various functionalities
    useEffect(() => {
        if (searchValue && chatFeature) {
            searchFeatures(searchValue);
        } else {
            setFeatures([]);
        }
    }, [searchValue, chatFeature, searchFeatures]);

    useEffect(() => {
        if (messages.length > 0) {
            messageSeen(messages[messages.length - 1].contact_id);
            const timer = setTimeout(() => {
                dispatch(getContacts(chatId));
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [messages, dispatch, chatId]);

    useEffect(() => {
        if (repliedMessage) {
            const message = messages.find((message) => message.id === repliedMessage.parentId);
            const messageAttachments = message?.attachments ? Object.values(message.attachments) : [];
            setRepliedImage(messageAttachments);
            console.log(messageAttachments, "attachment");
        }
    }, [repliedMessage, messages]);

    useEffect(() => {
        if (editMessages) {
            const content = messages.find((message) => message.id === editMessages);
            if (content) {
                setChat(content.message);
            }
        }
    }, [editMessages, messages]);

    useEffect(() => {
        const removeOrEditMessage = async (id) => {
            const index = messages.findIndex((message) => message.id === id);
            if (index !== -1) {
                dispatch(getContacts());
                if (temp.type === "delete") {
                    setDeletedId((prevDeletedId) => [...prevDeletedId, id]);
                } else if (temp.type === "edit") {
                    messages[index].message = temp.message;
                    setEditedMessages((prevEditedMessages) => [...prevEditedMessages, { id: id, message: temp.message }]);
                }
            }
        };
        if (messages.length > 0 && temp?.id) {
            removeOrEditMessage(temp.id);
        }
    }, [temp, messages, dispatch]);

    // Cleanup effect
    useEffect(() => {
        return () => {
            setDeletedId([]);
            setEditedMessages([]);
            setEditMessages(null);
            setRepliedMessage(null);
            setRepliedImage(null);
            setChat("");
            setTemp(null);
            setScrollPageNated(false);
            setAttachedFiles([]);
            setScrollType("down");
            setMessages([]);
        };
    }, [setScrollType, setMessages, setAttachedFiles]);

    return {
        chatControls: {
            messageEl,
            handleScroll,
            editMessages,
            setEditMessages,
            repliedMessage,
            setRepliedMessage,
            messageMenu,
            setMessageMenu,
            handleAttachments,
            showAttachments,
            handleCommand,
            handleMessageChange,
            handleSend,
            chat,
            setSearchText,
            hadleImagesModal,
            showImageModal,
            searchActive,
            toggleSearch
        },
        chatData: {
            editedMessages,
            deletedId,
            repliedImage,
            setRepliedImage,
            chatFeature,
            setChatFeature,
            features,
            setFeatures,
            attachedFiles,
            imagesOnModal,
            setImagesOnModal
        },
        context: {
            chatContext,
        }
    };
}

export default useChatArea