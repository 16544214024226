import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";

//post
export const postSeriesData = createAsyncThunk(
	"series/postSeriesData",
	(series) => {
		const seriesData = apiServices
			.post(`/seriess`, series.data)
			.then((response) => {
				// console.log('series ', response);
				return response;
			})
			.then(({ data }) => {
				let result = data;
				return result;
			})
			.catch((e) => e);
		return seriesData;
	}
);

//get series data
export const getSeriesData = createAsyncThunk("series/getSeriesData", () => {
	const seriesData = apiServices
		.get(`/seriess`)
		.then((response) => response)
		.then(({ data }) => {
			let result = data?.map((obj) => ({
				...obj,
				value: obj.id,
				label: obj.series_name,
			}));
			console.log(result)
			return result;
		});
	return seriesData;
});

//delete Series
export const deleteSeriesData = createAsyncThunk(
	"series/deleteSeriesData",
	async (id) => {
		const seriesData = await apiServices
			.delete(`/seriess/${id}`)
			.then((response) => {
				if (response.success) {
					// message.success(response.message);
				} else {
					message.error(response.message);
				}
				return id;
			});

		// console.log("deleted", seriesData);
		return seriesData;
	}
);

export const updateSeries = createAsyncThunk(
	"series/updateSeries",
	({ id, data }) => {
		const seriesData = apiServices
			.update(`/seriess/${id}`, data)
			.then((response) => {
				// console.log("update response", response);
				return response.data;
			});

		return seriesData;
	}
);

//get books of series
export const getBookOfSeries = createAsyncThunk(
	"series/getBookOfSeries",
	(id) => {
		const seriesData = apiServices
			.get(`/books-list/${id}`)
			.then((response) => response)
			.then(({ data }) => {
				let result = data?.map((obj) => ({
					...obj,
					value: obj.id,
					label: obj.series_name,
				}));
				return result;
			});
		return seriesData;
	}
);

const initialState = {
	seriesData: [],

	loading: false,
	error: false,
	edit: false,
	body: "",
	books: [],
	booksOfSeries: [],
};

const SeriesSlice = createSlice({
	name: "series",
	initialState,
	reducers: {
		setEdit: (state, action) => {
			state.edit = action.payload.edit;

			state.body = action.payload.body;
		},
		seriesSliceReset : (state) => {
			Object.assign(state, initialState);
		},
		setSeriesSelice : (state, action) => {
			state.seriesData = [action.payload]
		}
	},
	extraReducers: (builder) => {
		
    builder.addCase(postSeriesData.pending, (state) => {
			state.loading = true;
		})
		.addCase(postSeriesData.fulfilled, (state, action) => {
			state.loading = false;
			state.seriesData[0].unshift(action.payload);
			message.success("Series created successfully");
		})
		.addCase(postSeriesData.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			// console.log("series failure");
			message.error("Series was not created");
		})
		.addCase(getSeriesData.pending, (state) => {
			state.loading = true;
		})
		.addCase(getSeriesData.fulfilled, (state, action) => {
			state.loading = false;
			state.seriesData = [action.payload];
		})
		.addCase(getSeriesData.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})

		.addCase(deleteSeriesData.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteSeriesData.fulfilled, (state, action) => {
			state.loading = false;
			state.seriesData[0] = state.seriesData[0].filter(series => series.id !== action.payload);
			message.success("series deleted succesfully");
		})
		.addCase(deleteSeriesData.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("series not deleted");
		})

		.addCase(updateSeries.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateSeries.fulfilled, (state, action) => {
			state.loading = false;
			state.seriesData[0] = state.seriesData[0].map(series => series.id === action.payload.id ? action.payload : series);
			message.success("series updated successfully");
		})
		.addCase(updateSeries.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("series not updated");
		})
		.addCase(getBookOfSeries.pending, (state) => {
			state.loading = true;
		})
		.addCase(getBookOfSeries.fulfilled, (state, action) => {
			state.loading = false;
			state.booksOfSeries = [action.payload];
		})
		.addCase(getBookOfSeries.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})
	},
});

const SeriesReducer = SeriesSlice.reducer;
export const { setEdit, seriesSliceReset, setSeriesSelice } = SeriesSlice.actions;
export default SeriesReducer;
