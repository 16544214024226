import React, { useEffect, useState, useRef } from "react";

import {
	Drawer,
	Button,
	message,
	Form,
	Modal,
	Select as AntSelect,
} from "antd";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import newBook from "../../assets/images/newbook.png";
import "./addEvent.css";
import { getColorData } from "../../redux/Slice/ColorSlice";
import UserGallery from "../gallery/UserGallery";
import TextInput from "../Inputs/TextInput";
import TextAreaInput from "../Inputs/TextAreaInput";
import axios from "axios";
import { CollaborativeShareField } from "../collaborativeShareField";

const AddOutlineDrawer = ({ open, close, books, getOutlineByBook }) => {
	const selectColor = {
		value: "",
		label: "-- Select Color --",
	};

	const selectBook = {
		value: "",
		label: "-- Select Book --",
	};

	const [outlineName, setOutlineName] = useState("");
	const [outlineDescription, setOutlineDescription] = useState("");
	const [selectedFile, setSelectedFile] = useState(null);
	const [showGallery, setShowGallery] = useState(false);
	const [loading, setLoading] = useState(false);

	const [colorOptions, setColorOptions] = useState([]);
	const [selectedColor, setSelectedColor] = useState(selectColor);
	const [selectedBook, setSelectedBook] = useState(selectBook);

	const [error, setError] = useState({});
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [currentUser, setCurrentUser] = useState({ email: "", visible: false });

	const dispatch = useDispatch();

	const { colors } = useSelector((state) => state.colors);
	const { users } = useSelector((state) => state.user);

	const form = useRef();
	const [forms] = Form.useForm();

	useEffect(() => {
		dispatch(getColorData());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const options = colors.map((color) => {
			return {
				value: color.id,
				label: color.color,
			};
		});

		setColorOptions(() => [selectColor, ...options]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [colors]);

	const resetForm = () => {
		setSelectedBook(selectBook);
		setSelectedColor(selectColor);
		setSelectedFile(null);
		setOutlineName("");
		setOutlineDescription("");
		setError({});
		setSelectedUsers([]);
		forms.resetFields();
	};

	const saveOutline = async (e) => {
		const token = localStorage.getItem("token");
		const outlineData = {
			book_id: selectedBook.value,
			outline_name: outlineName,
			outline_title: "...",
			description: outlineDescription,
			color_id: selectedColor.value,
			image_id: selectedFile?.id ?? null,
		};

		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/outlines`,
				outlineData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				}
			);
			const outlineId = response.data.data.id;
			const collaboratorData = {
				email: selectedUsers.map((user) => user.email),
				role: selectedUsers.map((user) => user.role),
				collaboratable_type: "outlines",
				collaboratable_id: outlineId,
			};
			await axios.post(
				`${process.env.REACT_APP_API_URL}/collaborators`,
				collaboratorData,
				{
					headers: {
						Authorization: `Bearer ${token}`,
						Accept: "application/json",
					},
				}
			);
			getOutlineByBook(selectedBook);
			resetForm();
			setLoading(false);
			close();
		} catch (error) {
			if (error.response.status === 422) {
				setError(error.response.data.errors);
			} else if (error.response.status === 403) {
				message.error(error.response.data.message);
			}
			setLoading(false);
		}
	};

	const handleCollaboratorRole = (email) => {
		setCurrentUser({ email: email, visible: true });
	};

	const closeCollaboratorRole = () => {
		setCurrentUser({ email: "", role: "", visible: false });
	};

	const handleCancel = () => {
		resetForm();
		close();
	};

	return (
		<Drawer
			title={"Add New Outline"}
			placement="right"
			onClose={handleCancel}
			open={open}
			className="p-1 drawer"
			zIndex={9999}
			autoFocus={false}
			mask={false}
		>
			<Form
				ref={form}
				form={forms}
				labelCol={{
					span: 24,
				}}
				wrapperCol={{
					span: 24,
				}}
				layout="vertical"
				initialValues={{
					remember: false,
				}}
				onFinish={saveOutline}
				autoComplete="off"
				className="gx-signin-form gx-form-row0"
				style={{
					position: "relative",
				}}
			>
				<Form.Item label="Artwork">
					{showGallery && (
						<UserGallery
							setSelectedFile={setSelectedFile}
							setShowGallery={setShowGallery}
						/>
					)}
					<div className="artwork mt-3">
						<button
							className="btn-artwork"
							type="button"
							onClick={() => setShowGallery(!showGallery)}
						>
							<img src={selectedFile?.image_url ?? newBook} alt="Book Icon" />
						</button>
						{selectedFile && (
							<div className="artworkContainer mt-2">
								<button
									onClick={() => {
										setSelectedFile(null);
									}}
									type="button"
									className="btn-remove"
								>
									Remove
								</button>
							</div>
						)}
					</div>
				</Form.Item>
				<Form.Item
					label="Select Book"
					name="book_id"
					rules={[
						{
							required: true,
							message: "Please select a book",
						},
					]}
				>
					<Select
						options={[selectBook, ...books]}
						onChange={(e) => {
							setSelectedBook(e);
						}}
						isSearchable={true}
						className="select-book"
						value={selectedBook}
					/>
					{error.book_id && <span className="error">{error.book_id}</span>}
				</Form.Item>
				<Form.Item
					label="Name"
					name="outline_name"
					rules={[
						{
							required: true,
							message: "Name is required",
						},
					]}
				>
					<TextInput
						id="name"
						name="outline_name"
						maxLength={30}
						value={outlineName}
						onChange={(e) => setOutlineName(e.target.value)}
						error={error.outline_name}
					/>
				</Form.Item>
				<Form.Item
					label="Description"
					name="description"
					rules={[
						{
							required: true,
							message: "Description is required",
						},
					]}
				>
					<TextAreaInput
						id="description"
						name="description"
						maxLength={2048}
						showCount={true}
						value={outlineDescription}
						error={error.description}
						onChange={(e) => setOutlineDescription(e.target.value)}
					/>
				</Form.Item>
				<Form.Item
					label="Select Color"
					name="color_id"
					rules={[
						{
							required: true,
							message: "Color is required",
						},
					]}
				>
					<Select
						options={colorOptions}
						onChange={(e) => {
							setSelectedColor(e);
						}}
						isSearchable={true}
						className="select-book"
						value={selectedColor}
					/>
					{error.color_id && <span className="error">{error.color_id}</span>}
				</Form.Item>
				<CollaborativeShareField
					label="Share Outline"
					isRequired={false}
					handleCollaboratorRole={handleCollaboratorRole}
					closeCollaboratorRole={closeCollaboratorRole}
					Form={forms}
					users={users}
					selectedUsers={selectedUsers}
					setSelectedUsers={setSelectedUsers}
				/>
				<div className="buttons-container mt-3 d-flex space-between">
					<Button
						type="primary"
						className="mr-2"
						loading={loading}
						disabled={loading}
						htmlType="submit"
					>
						Save
					</Button>
					<Button type="ghost" onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			</Form>
			<Modal
				centered
				title="Select Collaborator Role"
				open={currentUser.visible && currentUser.email !== ""}
				onOk={closeCollaboratorRole}
				onCancel={closeCollaboratorRole}
			>
				<Form.Item
					label="Role"
					name="role"
					rules={[{ required: true, message: "Please select a role" }]}
				>
					<AntSelect
						placeholder="Select a role"
						defaultValue={
							selectedUsers.find((item) => item.email === currentUser.email)
								?.role
						}
						onSelect={(value) => {
							setSelectedUsers(
								selectedUsers.map((item) =>
									item.email === currentUser.email
										? { ...item, role: value }
										: item
								)
							);
						}}
					>
						<AntSelect.Option value="viewer">Viewer</AntSelect.Option>
						<AntSelect.Option value="editor">Editor</AntSelect.Option>
					</AntSelect>
				</Form.Item>
			</Modal>
		</Drawer>
	);
};

export default AddOutlineDrawer;
