import { Form } from "antd";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	getChapters,
	postChapters,
} from "../../redux/Slice/ChapterSlice";
import { getChapterCards } from "../../redux/Slice/ChapterCardsSlice";
import { getOutline } from "../../redux/Slice/OutlineSlice";
import { hasEditorAccess } from "../../redux/Slice/SharedWork";
import { useParams } from "react-router-dom";

const useOutlineDetail = ({ mod = "author" }) => {
	const dispatch = useDispatch();
	const { outlines } = useSelector((state) => state.sharedWork);
	const { id } = useParams();
	const sharedId = new URLSearchParams(window.location.search).get('sharedId');
	const email = new URLSearchParams(window.location.search).get('email');
	const key = new URLSearchParams(window.location.search).get('key');
	const { outline } = useSelector((state) => state.outline);
	const { colors } = useSelector((state) => state.colors);
	const { chapters } = useSelector((state) => state.chapters);
	const { chapterCards } = useSelector((state) => state.chaptercard);

	const title = outline[0]?.data?.outline_title || "Untitled Outline";
	const name = outline[0]?.data?.outline_name || "Unnamed";
	const desc = outline[0]?.data?.description || "No description available.";

	const [state, setState] = useState({
		isModalOpen: false,
		popupVisible: false,
		openIndex: null,
		isEditorModalVisible: false,
		loading: false,
		form: Form.useForm()[0],
	});

	const hasEditor = hasEditorAccess(outlines, id, sharedId);
	let guestData = {
		email,
		key,
	};

	// Fetch data on component mount
	useEffect(() => {
		const fetchData = async (guestData) => {
			console.log(guestData, 'guestData')
			setState((prev) => ({ ...prev, loading: true }));
			try {
				dispatch(getChapters({ id, mod, guestData }))
				dispatch(getChapterCards())
				dispatch(getOutline({ id, mod, guestData }))
			} catch (error) {
				console.error("Error fetching outline details:", error);
			} finally {
				setState((prev) => ({ ...prev, loading: false }));
			}
		};

		fetchData(guestData);
		return () => {
			localStorage.removeItem('dataFetched')
		}
	}, []);

	// Handlers
	const SubmitChapters = useCallback(
		(values) => {
			const data = { ...values, outline_id: parseInt(id, 10) };
			dispatch(postChapters({ data })).then(() => {
				state.form.resetFields();
				setState((prev) => ({ ...prev, isModalOpen: false }));
			});
		},
		[dispatch, id, state.form]
	);

	const handleOk = useCallback(() => {
		setState((prev) => ({ ...prev, isModalOpen: false }));
		SubmitChapters();
	}, [SubmitChapters]);

	const handleCancel = useCallback(() => {
		setState((prev) => ({ ...prev, isModalOpen: false }));
	}, []);

	const showModal = useCallback(() => {
		setState((prev) => ({ ...prev, isModalOpen: true }));
	}, []);

	const showEditorModal = useCallback(() => {
		setState((prev) => ({ ...prev, isEditorModalVisible: true }));
	}, []);

	const handleEditorModalOk = useCallback(() => {
		setState((prev) => ({ ...prev, isEditorModalVisible: false }));
	}, []);

	const handleEditorModalCancel = useCallback(() => {
		setState((prev) => ({ ...prev, isEditorModalVisible: false }));
	}, []);

	const close = useCallback(() => {
		setState((prev) => ({ ...prev, popupVisible: false }));
	}, []);

	const open = useCallback((index) => {
		setState((prev) => ({
			...prev,
			popupVisible: true,
			openIndex: index,
		}));
	}, []);

	return {
		...state,
		dispatch,
		hasEditor,
		id,
		title,
		name,
		desc,
		chapters,
		chapterCards,
		colors,
		showModal,
		handleOk,
		handleCancel,
		SubmitChapters,
		close,
		open,
		isEditorModalVisible: state.isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
	};
};

export { useOutlineDetail };