import axios from "axios";
import { message } from "antd";

// Create an Axios instance with default settings
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Request interceptor to add Authorization token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle global errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      // Network error
      message.error("Network error. Please try again.");
    } else if (error.response.status === 401) {
      console.log("401");
      // Run the code from file_context_0
      localStorage.removeItem('persist:root');
      localStorage.removeItem("token");
      localStorage.removeItem("authenticated");
      // Optionally, you can add additional logic here, such as redirecting to the login page
    } else {
      // Handle other errors
      // message.error("Something went wrong!");
    }
    return Promise.reject(error);
  }
);