import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiServices } from "../../services/apiServices/Api";
import { message } from "antd";

//post character
export const postCharacter = createAsyncThunk(
	"character/postCharacter",
	(char) => {
		const character = apiServices
			.post(`/timeline-characters`, char.data)
			.then((response) => response.data)
			.catch((err) => console.log(err));
		return character;
	}
);

//get CharacterList
export const getCharacters = createAsyncThunk(
	"character/getCharacters",
	async ({id, mod, geustData}) => {
		const character = await apiServices.get(`/timeline-characters-list/${id}${mod === 'guest' ? `?email=${geustData.email}&key=${geustData.key}` : ''}`);

		return character;
	}
);

//update character
export const updateCharacter = createAsyncThunk(
	"character/updateCharacter",
	async ({ id, data }) => {
		const character = apiServices
			.update(`/timeline-characters/${id}`, data)
			.then((response) => {
				return response.data;
			});

		return character;
	}
);

//deleteCharacter

export const deleteCharacter = createAsyncThunk(
	"character/deleteCharacter",
	async (id) => {
		const character = apiServices
			.delete(`/timeline-characters/${id}`)
			.then((response) => {
				return id;
			});

		return character;
	}
);

const initialState = {
	characters: [],
	loading: false,
	error: false,
	char: [],
};

const CharacterSlice = createSlice({
	name: "character",
	initialState,
	reducers: {
		characterSeliceReset : (state) => {
			Object.assign(state, initialState);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getCharacters.pending, (state) => {
			state.loading = true;
		})
		.addCase(getCharacters.fulfilled, (state, action) => {
			state.loading = false;
			state.characters = action.payload;
		})
		.addCase(getCharacters.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
		})

		.addCase(postCharacter.pending, (state) => {
			state.loading = true;
		})
		.addCase(postCharacter.fulfilled, (state, action) => {
			state.loading = false;
			console.log(action.payload, 'action.payload')
			state.characters.push(action.payload);
			message.success("Character created successfully");
		})
		.addCase(postCharacter.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			message.error("Character not created ");
		})

		.addCase(updateCharacter.pending, (state) => {
			state.loading = true;
		})
		.addCase(updateCharacter.fulfilled, (state, action) => {
			state.loading = false;
			state.characters = state.characters.map(character => character.id === action.payload.id ? action.payload : character);
			message.success("Character updated successfully");
		})
		.addCase(updateCharacter.rejected, (state, action) => {
			state.loading = true;
			state.error = [action.payload];
			message.error("Character not updated");
		})
		.addCase(deleteCharacter.pending, (state) => {
			state.loading = true;
		})
		.addCase(deleteCharacter.fulfilled, (state, action) => {
			state.loading = false;
			state.characters = state.characters.filter(character => character.id !== action.payload);
			message.success("Character deleted successfully");
    })
		.addCase(deleteCharacter.rejected, (state, action) => {
			state.loading = true;
			state.error = action.payload;
			message.error("Character not deleted");
		})
	},
});

const CharacterReducer = CharacterSlice.reducer;
export const { characterSeliceReset } = CharacterSlice.actions;

export default CharacterReducer;
