import { useState, useEffect, useCallback } from "react";
import { getBooks, getBrainstorms } from "../../helpers/GetList";
import axios from "axios";
import { message } from "antd";
import { mapFeaturesToButtons } from "../../components/PackageSubscriptionHook";
import { useDispatch, useSelector } from "react-redux";
import { setBrainstormValues } from "../../redux/Slice/BrainStormSlice";

const useBrainstorms = (id, token) => {
  const dispatch = useDispatch();
  const { bstorm } = useSelector((state) => state.brainstorm);
  
  // Consolidated State
  const [state, setState] = useState({
    books: [],
    brainstorms: bstorm,
    totalRecords: 0,
    currentPage: 1,
    selectedBrainstorm: null,
    selectedBook: {
      value: "",
      label: "-- Any Book --",
    },
    showAddNewDrawer: false,
	showActionDrawer: false,
    showEditDrawer: false,
    subscriptionModal: false,
    controlledButtons: {
      createBrainstorm: false,
      feature: "",
    },
    clickedButton: [],
    loading: false,
  });

  const {
    books,
    brainstorms,
    totalRecords,
    currentPage,
    selectedBrainstorm,
    selectedBook,
    showAddNewDrawer,
    showEditDrawer,
	showActionDrawer,
    subscriptionModal,
    controlledButtons,
    clickedButton,
    loading,
  } = state;

  // Fetch Books and Initial Brainstorms on Mount
  useEffect(() => {
    const fetchInitialData = async () => {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        await getBooks(id, (book) => {
          setState((prev) => ({ ...prev, selectedBook: book }));
        }, (booksList) => {
          setState((prev) => ({ ...prev, books: booksList }));
        });

        await getBrainstorms(id, 1, (data) => {
          setState((prev) => ({ ...prev, brainstorms: data }));
        }, (total) => {
          setState((prev) => ({ ...prev, totalRecords: total }));
        }, dispatch);

      } catch (error) {
        message.error("Failed to load initial data.");
        console.error("Error fetching initial data:", error);
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    };

    fetchInitialData();
  }, [id, dispatch]);

  // Fetch Brainstorms Based on Selected Book
  const getBrainstormsByBook = useCallback(async (book) => {
    setState((prev) => ({ ...prev, loading: true, selectedBook: book }));
    try {
      const bookId = book.value || id; // Use main id if no book selected
      await getBrainstorms(bookId, 1, (data) => {
        setState((prev) => ({ ...prev, brainstorms: data, currentPage: 1 }));
      }, (total) => {
        setState((prev) => ({ ...prev, totalRecords: total }));
      }, dispatch);
    } catch (error) {
      message.error("Failed to fetch brainstorms.");
      console.error("Error fetching brainstorms by book:", error);
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [id, dispatch]);

  // Handle Page Click for Pagination
  const handlePageClick = useCallback(async (page) => {
    setState((prev) => ({ ...prev, loading: true, currentPage: page }));
    try {
      const bookId = selectedBook.value || id;
      await getBrainstorms(bookId, page, (data) => {
        setState((prev) => ({ ...prev, brainstorms: data }));
      }, (total) => {
        setState((prev) => ({ ...prev, totalRecords: total }));
      }, dispatch);
    } catch (error) {
      message.error("Failed to fetch brainstorms for the selected page.");
      console.error("Error fetching brainstorms for page:", error);
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [selectedBook.value, id, dispatch]);

  // Edit Brainstorm Handler
  const editBrainstorm = useCallback(async (brainstormId) => {
    if (controlledButtons.createBrainstorm) {
      setState((prev) => ({ ...prev, loading: true }));
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/brainstorms/${brainstormId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        setState((prev) => ({
          ...prev,
          selectedBrainstorm: response.data.data,
          showEditDrawer: true,
          loading: false,
        }));
      } catch (error) {
        message.error("Could not fetch Brainstorm.");
        console.error("Error fetching brainstorm:", error);
        setState((prev) => ({ ...prev, loading: false }));
      }
    } else {
      setState((prev) => ({
        ...prev,
        clickedButton: [{ feature: "brainstorming-feature" }],
        subscriptionModal: true,
      }));
    }
  }, [controlledButtons.createBrainstorm, token]);

  // Delete Brainstorm Handler
  const deleteBrainstorm = useCallback((brainstormId) => {
    if (controlledButtons.createBrainstorm) {
      setState((prev) => ({ ...prev, loading: true }));
      axios.delete(`${process.env.REACT_APP_API_URL}/brainstorms/${brainstormId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      })
      .then(() => {
        // Refresh brainstorms
        const bookId = selectedBook.value || id;
        return getBrainstorms(bookId, 1, (data) => {
          setState((prev) => ({
            ...prev,
            brainstorms: data,
            totalRecords: data.length, // Adjust based on your API's total records
            currentPage: 1,
            loading: false,
          }));
        }, (total) => {
          setState((prev) => ({ ...prev, totalRecords: total }));
        }, dispatch);
      })
      .then(() => {
        message.success("Brainstorm deleted successfully.");
      })
      .catch((error) => {
        message.error("Could not delete Brainstorm.");
        console.error("Error deleting brainstorm:", error);
        setState((prev) => ({ ...prev, loading: false }));
      });
    } else {
      setState((prev) => ({
        ...prev,
        clickedButton: [{ feature: "brainstorming-feature" }],
        subscriptionModal: true,
      }));
    }
  }, [controlledButtons.createBrainstorm, token, selectedBook.value, id, dispatch]);

  // Check Subscription based on Action
  const checkSubscription = useCallback((action) => {
    const featureMap = {
      "brainstorming-feature": "createBrainstorm",
    };
    const updatedButtons = mapFeaturesToButtons(featureMap, action);
    setState((prev) => ({ ...prev, controlledButtons: updatedButtons }));
  }, []);

  // Open Subscription Modal
  const openSubscriptionModal = useCallback(() => {
    setState((prev) => ({ ...prev, subscriptionModal: true }));
  }, []);

  // Close Subscription Modal
  const closeSubscriptionModal = useCallback(() => {
    setState((prev) => ({ ...prev, subscriptionModal: false }));
  }, []);

  const setShowAddNewDrawer = (value) => {
    setState((prev) => ({ ...prev, showAddNewDrawer: value }));
  };

  const setShowEditDrawer = (value) => {
    setState((prev) => ({ ...prev, showEditDrawer: value }));
  };

  const setSubscriptionModal = (value) => {
    setState((prev) => ({ ...prev, subscriptionModal: value }));
  };

  const setClickedButton = (buttons) => {
    setState((prev) => ({ ...prev, clickedButton: buttons }));
  };

  const setShowActionDrawer = (value) => {
    setState((prev) => ({ ...prev, showActionDrawer: value }));
  };

  return {
    books,
    brainstorms,
    totalRecords,
    currentPage,
    selectedBrainstorm,
    selectedBook,
    showAddNewDrawer,
    showEditDrawer,
	showActionDrawer,
    subscriptionModal,
    controlledButtons,
    clickedButton,
    loading,
    setShowAddNewDrawer,
    setShowEditDrawer,
    setSubscriptionModal,
    setClickedButton,
    getBrainstormsByBook,
    handlePageClick,
    editBrainstorm,
    deleteBrainstorm,
    checkSubscription,
    openSubscriptionModal,
    closeSubscriptionModal, // Provided closure for subscription modal
    setShowActionDrawer,
  };
};

export { useBrainstorms };