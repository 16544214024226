import { Card, Row, Col, Button, Dropdown, Alert } from "antd";
import React, { useState } from "react";
import BookNameOutline from "../cards/outline-cards/book-name-outline/BookNameOutlineCard";
import { EditFilled, PlusOutlined } from "@ant-design/icons";
import Menu from "../../assets/icons/menu.svg";
import { Popover, Modal } from "antd";
import FullPageSpinner from "../loader/FullPageSpinner";
import PlotPlannerModal from "./PlotPlannerModal";
import PlotPlanner from "./PlotPlanner";
import EditPlotLineModal from "../EditPlotLineModal";
import AddNewMarkerModal from "../AddNewMarkerModal";
import EditMarkerModal from "../EditMarkerModal";
import { AUTHENTICATED_ROUTES } from "../../constant/routeConstant";
import { usePlotlineDetail } from "../../hooks/plotlines/usePlotlineDetails";

const PlotlineDetail = (props) => {
	const { mod = "auther" } = props;
	const {
		hasEditor,
		currentData,
		setCurrentData,
		scroll,
		enableCursor,
		loading,
		name,
		desc,
		open,
		lineIndex,
		confirmLoading,
		newMarkerOpened,
		editMarkerOpened,
		setLineIndex,
		setEnableCursor,
		setNewMarkerOpened,
		setEditMarkerOpened,
		handleOk,
		handleCancel,
		showModal,
		handleUpdateOk,
		handleUpdateCancelModal,
		handleEditeOk,
		handleEditCancleModal,
		AddNewMarker,
		EditMarkerModalSuccess,
		editPlotlineHandler,
		isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
		errorMessage
	} = usePlotlineDetail({ mod });

	if (errorMessage && !loading) {
		return (
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px', width: '100%' }}>
				<Card 
					style={{ 
						padding: '30px', 
						border: '1px solid #f5c6cb', 
						borderRadius: '12px', 
						backgroundColor: '#f8d7da', 
						width: '50%',
						boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
					}}
				>
					<p style={{ 
						color: '#721c24', 
						fontSize: '20px', 
						marginBottom: '15px', 
						fontWeight: 'bold' 
					}}>
						{errorMessage} !
					</p>
					<Alert 
						message="Error" 
						description={errorMessage} 
						type="error" 
						showIcon 
						style={{ marginTop: '10px' }} 
					/>
				</Card>
			</div>
		);
	}

	const Content = ({ e_id, path_id, data }) => {
		const [isModalOpen, setIsModalOpen] = useState(false);

		const deleteevent = (eid) => {
			editPlotlineHandler({ param: "onDelete", ids: eid });
		};
		const showUpdateModal = () => {
			setIsModalOpen(true);
		};
		const handleUpdateOk = () => {
			setIsModalOpen(false);
		};
		const handleUpdateCancel = () => {
			setIsModalOpen(false);
		};

		const EditEvent = (data) => {
			setTimeout(() => {
				editPlotlineHandler({
					param: "",
					ids: -1,
					selectedColor: data.color_code,
					plotlineNameUpdated: data.event_type,
				});
			}, 500);
		};

		return (
			<div>
				<div
					className="edit"
					onClick={(e) => {
						e.preventDefault();
						hasEditor ? showUpdateModal() : showEditorModal();
					}}
				>
					<button>
						Edit
						<span className="ml-2">
							<i className="fa fa-check"></i>
						</span>
					</button>
				</div>

				<div className="delete-btn">
					<button
						onClick={() => {
							hasEditor ? deleteevent(e_id) : showEditorModal();
						}}
					>
						Delete
						<span className="ml-2">
							<i className="fa fa-times"></i>
						</span>
					</button>
				</div>
				<EditPlotLineModal
					title={data?.title}
					lineColor={data?.lineColor}
					open={isModalOpen}
					ok={handleUpdateOk}
					cancel={handleUpdateCancel}
					onSubmit={EditEvent}
					event_id={e_id}
				/>
			</div>
		);
	};

	return (
		<div className="m-3">
			<BookNameOutline
				link={AUTHENTICATED_ROUTES.PLOT_LINE}
				name={loading ? "Loading Name..." : name}
				description={loading ? "Loading Description..." : desc}
				mod={mod}
			/>
			{loading || confirmLoading ? (
				<FullPageSpinner />
			) : (
				<Card className="mt-2" style={{ borderRadius: "10px" }}>
					<Row gutter={24}>
						{currentData?.length > 0 &&
							currentData?.map((p, index) => (
								<Col key={index} sm={12} xs={24} lg={6} md={6} xl={6}>
									<div className="mb-5 d-flex justify-between">
										<div className="chapter-name d-flex justify-between new-dist-flx">
											<span
												style={{
													width: "18px",
													height: "18px",
													background: p.pointerColor,
													borderRadius: "110px",
												}}
												className="mr-2"
											></span>
											<p className="mr-3">{p.title}</p>
											{mod !== "guest" && (
												<div>
													<span>
														{hasEditor ? (
															<Popover
																id={p.id}
																popupVisible={false}
																placement="rightBottom"
																content={
																	<Content
																		e_id={index}
																		path_id={index}
																		data={p}
																	/>
																}
																title="Actions"
																trigger="click"
																style={{ cursor: "pointer" }}
															>
																<img
																	onClick={() =>
																		hasEditor
																			? setLineIndex(index)
																			: showEditorModal()
																	}
																	src={Menu}
																	className="ml-4"
																	alt="menu-icon"
																/>
															</Popover>
														) : (
															<img
																onClick={() =>
																	hasEditor
																		? setLineIndex(index)
																		: showEditorModal()
																}
																src={Menu}
																className="ml-4"
																alt="menu-icon"
															/>
														)}
													</span>
												</div>
											)}
										</div>
									</div>
								</Col>
							))}

						{mod !== "guest" && (
							<Col className="plotLine-btn">
								<Button
									type="primary"
									size="small"
									className=""
									style={{ cursor: "pointer" }}
									onClick={hasEditor ? showModal : showEditorModal}
								>
									New Plotline
								</Button>
							</Col>
						)}
					</Row>

					{mod !== "guest" && (
						<div className="mt-3 edit-mark">
							<Dropdown
								className="edit-btn"
								overlay={editmenu}
								placement="bottomCenter"
								forceRender
							>
								<Button
									onClick={() =>
										hasEditor ? setEditMarkerOpened(true) : showEditorModal()
									}
									icon={<EditFilled />}
									size="large"
									className="edit-btn"
								>
									Edit Marker
								</Button>
							</Dropdown>
							<br />
							<Dropdown
								className="edit-btn"
								overlay={newMarkerMenu}
								placement="bottomCenter"
								forceRender
							>
								<Button
									onClick={() =>
										hasEditor ? setNewMarkerOpened(true) : showEditorModal()
									}
									icon={<PlusOutlined />}
									size="large"
									className="add-btn mt-2"
								>
									New Marker
								</Button>
							</Dropdown>
						</div>
					)}
					<AddNewMarkerModal
						open={newMarkerOpened}
						ok={handleUpdateOk}
						cancel={handleUpdateCancelModal}
						onSubmit={AddNewMarker}
						plotLines={currentData}
						event_id={1}
					/>
					<EditMarkerModal
						open={editMarkerOpened}
						ok={handleEditeOk}
						cancel={handleEditCancleModal}
						onSubmit={EditMarkerModalSuccess}
						plotLines={currentData}
						event_id={1}
					/>
					<PlotPlanner
						enableCursor={enableCursor}
						editor={hasEditor && mod !== "guest"}
						editPlotlineHandler={() =>
							enableCursor && editPlotlineHandler({ param: "onEnd" })
						}
						scroll={scroll}
						data={currentData}
						cursorEnableFunc={() => {
							setEnableCursor(!enableCursor);
						}}
						onChange={(data) => setCurrentData(data)}
					/>
					<PlotPlannerModal
						handleOk={(data) => handleOk(data)}
						handleCancel={handleCancel}
						isModalOpen={open}
					/>
				</Card>
			)}
			<Modal
				title="Access Denied"
				visible={isEditorModalVisible}
				onOk={handleEditorModalOk}
				onCancel={handleEditorModalCancel}
				centered
				footer={null}
			>
				<p>You do not have permission to perform this action.</p>
			</Modal>
		</div>
	);
};

const editmenu = <></>;

const newMarkerMenu = <></>;

export default PlotlineDetail;
