// Authenticated.js
import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "../constant/routeConstant";
import Unauthenticated from "./Unauthenticated";
import Main from "../components/layout/Main";
import Dashboard from "../pages/screen/Dashboard";
import ChatContext from "../context/ChatContext";
import Chat from "../pages/screen/NewChat/index";
import Calendar from "../pages/screen/Calendar";
import Books from "../pages/screen/Books";
import Brainstorms from "../components/brainstorm/Brainstorms";
import Outlines from "../pages/screen/outline/Outlines";
import Shared from "../pages/screen/Shared";
import Plotlines from "../components/plotline/Plotlines";
import Timelines from "../components/timeline/Timelines";
import Settings from "../pages/screen/Settings/index";
import ProfileSettings from "../pages/screen/Settings/ProfileSetting";
import GetHelp from "../pages/screen/GetHelp/GetHelp";
import PlotlineDetail from "../components/plotline/Plotline-detail";
import TimelineDetail from "../components/timeline/TimelineDetails";
import OutlineDetail from "../pages/screen/outline/OutlineDetail";
import BrainstormDetails from "../components/brainstorm/BrainStormDetails";
import BookOfSeriesCards, { BookOfSeriesDashboard } from "../components/BookofSeriesCards";
import SharedDetails from "../pages/sharedDetails";
import ActivityDetails from "../pages/screen/activityDetails";
import GuestView from "../pages/screen/guestview/guestview";
import Plains from "../pages/Plains";
import SuccessSubscription from "../pages/SuccessSubscription";
import { useSelector } from "react-redux";
import BookDetails from "../pages/screen/Book-Details";

export const authenticatedRoutes = [
  { path: AUTHENTICATED_ROUTES.DASHBOARD, element: <Dashboard /> },
  { path: AUTHENTICATED_ROUTES.CHAT, element: <ChatContext><Chat /></ChatContext> },
  { path: AUTHENTICATED_ROUTES.CALENDAR, element: <Calendar /> },
  { path: AUTHENTICATED_ROUTES.BOOKS, element: <Books /> },
  { path: AUTHENTICATED_ROUTES.BRAINSTORM, element: <Brainstorms /> },
  { path: AUTHENTICATED_ROUTES.OUT_LINE, element: <Outlines /> },
  { path: AUTHENTICATED_ROUTES.SHARED_WORKS, element: <Shared /> },
  { path: AUTHENTICATED_ROUTES.PLOT_LINE, element: <Plotlines /> },
  { path: AUTHENTICATED_ROUTES.TIME_LINE, element: <Timelines /> },
  { path: AUTHENTICATED_ROUTES.SETTING, element: <Settings /> },
  { path: AUTHENTICATED_ROUTES.PROFILE_SETTING, element: <ProfileSettings /> },
  { path: AUTHENTICATED_ROUTES.GET_HELP, element: <GetHelp /> },
  { path: "*", element: <Navigate to={AUTHENTICATED_ROUTES.DASHBOARD} /> },
  { path: "/book-detial/:id", element: <BookDetails />},
  { path: "/plotline/:id", element: <PlotlineDetail /> },
  { path: "/timeline/:id", element: <TimelineDetail /> },
  { path: "/outline/:id", element: <OutlineDetail /> },
  { path: "/brainstorm/:id", element: <BrainstormDetails /> },
  { path: "/shared-details/:id", element: <SharedDetails /> },
  { path: "/brain-storm/list/:id", element: <Brainstorms /> },
  { path: "/out-line/list/:id", element: <Outlines /> },
  { path: "/plot-line/list/:id", element: <Plotlines /> },
  { path: "/time-line/list/:id", element: <Timelines /> },
  { path: "/series/out-line/:id", element: <BookOfSeriesCards /> },
  { path: "/series/plot-line/:id", element: <BookOfSeriesCards /> },
  { path: "/series/time-line/:id", element: <BookOfSeriesCards /> },
  { path: "/series/brain-storm/:id", element: <BookOfSeriesCards /> },
  { path: "/series/dashboard/:id", element: <BookOfSeriesDashboard /> },
  { path: "/activity-details", element: <ActivityDetails /> },
  { path: "/guest-view/:type/:id", element: <GuestView />},
  { path: "/plans", element: <Plains/>},
  { path: "/subscription/success", element: <SuccessSubscription /> },
];

const freeUserRoutes = [
  { path: AUTHENTICATED_ROUTES.SHARED_WORKS, element: <Shared /> },
  { path: "/book-detial/:id", element: <BookDetails />},
  { path: "/guest-view/:type/:id", element: <GuestView />},
  { path: "/shared-details/:id", element: <SharedDetails /> },
  { path: "/plans", element: <Plains/>},
  { path: "/subscription/success", element: <SuccessSubscription /> },
  { path: AUTHENTICATED_ROUTES.PROFILE_SETTING, element: <ProfileSettings /> },
  { path: "/plotline/:id", element: <PlotlineDetail /> },
  { path: "/timeline/:id", element: <TimelineDetail /> },
  { path: "/outline/:id", element: <OutlineDetail /> },
  { path: "/brainstorm/:id", element: <BrainstormDetails /> },
  { path: AUTHENTICATED_ROUTES.SETTING, element: <Settings /> },
  { path: "*", element: <Navigate to={AUTHENTICATED_ROUTES.Plans} /> },
];


export default function Authenticated({ isLoggedIn }) {
  const { user } = useSelector((state) => state.auth);
  const hasPackage = user[0]?.user?.package;

  useEffect(() => {
    // Update localStorage whenever hasPackage changes
    if (hasPackage) {
      localStorage.setItem("hasPackage", "true");
    } else if(user[0]?.user?.package === null) {
      localStorage.setItem("hasPackage", "false");
    }
  }, [hasPackage]);

  const getRoutes = () => {
    // Check if hasPackage is defined
    if (typeof hasPackage !== "undefined") {
      return hasPackage ? authenticatedRoutes : freeUserRoutes;
    }
    
    // Fallback to localStorage if network is unavailable
    const storedHasPackage = localStorage.getItem("hasPackage");
    if (storedHasPackage === "true") {
      return authenticatedRoutes;
    } else if (storedHasPackage === "false") {
      return freeUserRoutes;
    }
    
    // Default to freeUserRoutes if no data is available
    return freeUserRoutes;
  };

  if (!isLoggedIn) {
    return <Unauthenticated />;
  }
  return (
    <div className="App">
      <Main>
        <Routes>
          {getRoutes().map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Main>
    </div>
  );
}
