import React, { useState } from "react";
import { Popconfirm, Popover, Tag } from "antd";

// import Menu from "../assets/icons/menu.svg";
// import Share from "../assets/icons/share.svg";
// import Message from "../assets/icons/message.svg";
import WebIcon from "../../assets/icons/web-icon.svg";

import "../cards/index.scss";
import { getTimeDuration } from "../../services/utilServices";
import newBook from "../../assets/images/newbook.png";
import { MenuButton } from "../button/MenuButton";
import { CommentOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
const TimelineCard = ({
	style,
	timeline,
	editTimeline,
	deleteTimeline,
	isShared,
	shareId,
	showCommentDrawer,
}) => {
	const [showAction, setShowAction] = useState(false);

	return (
		<div className="book-card-container" id={timeline.id} style={style}>
			<div className="display-flex space-between jp-flex">
				<div>
					{/* <img src={Share} className="mr-1 align-center" alt="Share Icon" /> */}
					<ShareAltOutlined
						className="mr-1 align-center"
						style={{
							color: timeline.foreground_color,
						}}
					/>
					<span
						className="thin-text-13 mr-2"
						style={{
							color: timeline.foreground_color,
						}}
					>
						Shared by:
					</span>
					<img src={WebIcon} className="mr-1" alt="Web Icon" />
					<span
						className="bold-text-13"
						style={{
							color: timeline.foreground_color,
						}}
					>
						Shana Tenerths
					</span>
				</div>
				<div>
					<CommentOutlined
						style={{
							color: timeline.foreground_color,
						}}
						className="mr-2"
						onClick={showCommentDrawer}
					/>
					<Tag className="bold-white-text-11" onClick={showCommentDrawer}>
						{timeline.comments.length ?? 0} Comment
					</Tag>
					<Popover
						popupVisible={false}
						placement="right"
						content={
							<>
								<div className="edit">
									<button
										onClick={() => {
											editTimeline(timeline.id);
											setShowAction(false);
										}}
										type="button"
									>
										Edit <i className="fa fa-check ml-2"></i>
									</button>
								</div>

								<div className="delete-btn">
									<Popconfirm
										title="Delete Timeline"
										description="Are you sure to delete this timeline?"
										onConfirm={() => deleteTimeline(timeline.id)}
									>
										<button type="button">
											Delete <i className="fa fa-times ml-2"></i>
										</button>
									</Popconfirm>
								</div>
							</>
						}
						open={showAction}
						title="Actions"
						trigger="click"
						style={{ cursor: "pointer" }}
					>
						{/* <img src={Menu} alt="Menu Icon" onClick={open} id={id} /> */}
						<MenuButton
							onClick={() => setShowAction(!showAction)}
							id={timeline.id}
							color={timeline.foreground_color}
						/>
					</Popover>
				</div>
			</div>
			<Link
				to={`/timeline/${timeline.id}${isShared ? `?sharedId=${shareId}` : ""}`}
			>
				<div className="display-flex mt-2 box-gap">
					<img
						src={timeline.image_url || newBook}
						className="self-start"
						alt=""
						width="70"
						height="70"
					/>

					<div>
						<h2
							className="sub-title-16 font-w-800 mb-0-3"
							style={{
								color: timeline.foreground_color,
							}}
						>
							<Link
								to={`/timeline/${timeline.id}`}
								style={{
									color: timeline.foreground_color,
								}}
							>
								{timeline.name}
							</Link>
						</h2>
						<h4
							className="thin-text-13"
							style={{ lineHeight: 1.5, color: timeline.foreground_color }}
						>
							{timeline.description}
						</h4>
						<div className="display-flex mt-2">
							<p
								className="thin-text-13 mr-2"
								style={{
									color: timeline.foreground_color,
								}}
							>
								Updated: {getTimeDuration(timeline.updated)}
							</p>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default TimelineCard;
