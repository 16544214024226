import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getPlotPlanner } from "../../redux/Slice/PlotlineSlice";
import { useParams } from "react-router-dom";
import axios from "axios";
import { hasEditorAccess } from '../../redux/Slice/SharedWork';
import { message } from 'antd';


const defaultData = [
	{
		data: [
			{
				custom_label: "Rising Action",
				x: 2.054178032993285,
				y: 3.5258217395191456,
			},
			{ custom_label: "Climax", x: 2.884297520661157, y: 4.899843600993984 },
			{
				custom_label: "Declining Action",
				x: 3.5270890164966424,
				y: 4.004694978955766,
			},
			{
				custom_label: "Exposition",
				x: 1.0183653608169767,
				y: 2.1455399061032865,
			},
			{
				custom_label: "Resolution",
				x: 4.735537190082645,
				y: 2.9123632583259975,
			},
		],
		title: "default",
		lineColor: "#225e66",
		pointerColor: "#225e66",
	},
];

const usePlotlineDetail = (props) => {
	const { mod } = props;
    const { plotlines } = useSelector((state) => state.sharedWork);
	const [currentData, setCurrentData] = useState(defaultData);
	const [scroll] = useState(false);
	const [enableCursor, setEnableCursor] = useState(false);
	const { loading, plotplanner } = useSelector((state) => state.plotline);
	const name = plotplanner[0]?.data?.plot_planner_title;
	const desc = plotplanner[0]?.data?.description;

	const dispatch = useDispatch();
	const { id } = useParams();
	const sharedId = new URLSearchParams(window.location.search).get('sharedId');
	const email = new URLSearchParams(window.location.search).get('email')
	const key = new URLSearchParams(window.location.search).get('key')
	const [open, setOpen] = useState(false);
	const [lineIndex, setLineIndex] = useState(-1);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [newMarkerOpened, setNewMarkerOpened] = useState(false);
	const [editMarkerOpened, setEditMarkerOpened] = useState(false);
	const [isEditorModalVisible, setIsEditorModalVisible] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);

    let hasEditor = hasEditorAccess(plotlines, id, sharedId);
	let geustData = {
		email,
		key
	}
	useEffect(() => {
		getPlotPlannerDetails({ setErrorMessage });
		dispatch(getPlotPlanner({id, mod, geustData, setErrorMessage }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getPlotPlannerDetails = async ({ setErrorMessage = () => {} }) => {
		setConfirmLoading(true);
		try {
			const { data, } = await axios.get(
				`${process.env.REACT_APP_API_URL}/plot-planners/${id}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			if (data) {
				if (JSON.parse(data.data.plotlines_json)) {
					setCurrentData(JSON.parse(data.data.plotlines_json));
				}
				setConfirmLoading(false);
			}
			if(data.status === 404) {
				setErrorMessage("Plotline not found");
			}
		} catch (error) {
			console.debug(error);
			setConfirmLoading(false);
		}
	};

	const addNewPlotlineHandler = async (data) => {
		const { plotline_title, lineColor, plot_planner_id } = data;
		if (plotline_title === "" || lineColor === "") {
			return;
		}
		setConfirmLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/save-plot-lines/${plot_planner_id}`,
				{
					plot_planner_id: plot_planner_id,
					plotlines_json: JSON.stringify([
						...currentData,
						{
							title: plotline_title,
							pointerColor: lineColor,
							lineColor: lineColor,
							data: [
								{
									x: 1,
									y: 3,
									custom_label: `${plotline_title} 1`,
								},
								{
									x: 3,
									y: 3,
									custom_label: `${plotline_title} 2`,
								},
								{
									x: 5,
									y: 3,
									custom_label: `${plotline_title} 3`,
								},
							],
						},
					]),
				},
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			if (response.data) {
				getPlotPlannerDetails();
				setConfirmLoading(false);
			}
		} catch (e) {
			setConfirmLoading(false);
		}
	};

	const editPlotlineHandler = async ({
		param = "",
		ids = -1,
		plotlineNameUpdated = "",
		selectedColor = "",
		markerLabel,
		selectedPlot,
		selectedLabel,
		selectedLabelText,
	}) => {
		setEnableCursor(false);
		if (currentData.length > 0) {
			if (plotlineNameUpdated === "" && param === "") {
				return;
			}
			if (selectedColor === "" && param === "") {
				return;
			}
			setConfirmLoading(true);
			try {
				let newD = [...currentData];
				if (param === "") {
					newD[lineIndex].title = plotlineNameUpdated;
					newD[lineIndex].pointerColor = selectedColor;
					newD[lineIndex].lineColor = selectedColor;
				} else if (param === "onDelete") {
					newD.splice(ids, 1);
				} else if (param === "onEnd") {
					newD = [...currentData];
				} else if (param === "onAddMarker") {
					if (selectedPlot < 0) {
						setConfirmLoading(false);
						message.info("Please select a plotline.");
						return;
					}
					if (currentData[selectedPlot].data.length > 10) {
						setConfirmLoading(false);
						message.info("You can only add 10 markers to a plotline.");
						return;
					}
					newD[selectedPlot].data.push({
						x: 3,
						y: 3.2,
						custom_label: markerLabel,
					});
				} else if (param === "onEditMarker") {
					newD[selectedPlot].data[selectedLabel].custom_label =
						selectedLabelText;
				} else if (param === "onDeleteMarker") {
					newD[selectedPlot].data.splice(selectedLabel, 1);
				}

				setNewMarkerOpened(false);

				const response = await axios.post(
					`${process.env.REACT_APP_API_URL}/save-plot-lines/${id}`,
					{
						plot_planner_id: id,
						plotlines_json: JSON.stringify(newD),
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("token")}`,
						},
					}
				);
				if (response.data) {
					getPlotPlannerDetails();
					setConfirmLoading(false);
					setNewMarkerOpened(false);
					message.success("Plotline updated successfully");
				}
			} catch (e) {
				console.log(e);
				setConfirmLoading(false);
				setNewMarkerOpened(true);
			}
		}
	};

	const handleOk = (data) => {
		addNewPlotlineHandler(data);
		setConfirmLoading(true);
		setOpen(false);
		setConfirmLoading(false);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	const showModal = () => {
		setOpen(true);
	};

	const handleUpdateOk = () => {
		setNewMarkerOpened(false);
	};

	const handleUpdateCancelModal = () => {
		setNewMarkerOpened(false);
	};

	const handleEditeOk = () => {
		setEditMarkerOpened(false);
	};

	const handleEditCancleModal = () => {
		setEditMarkerOpened(false);
	};

	const AddNewMarker = (data) => {
		setNewMarkerOpened(false);
		console.log("data", data);
		editPlotlineHandler({
			param: "onAddMarker",
			markerLabel: data.event_type,
			selectedPlot: data.selectedPlot,
		});
	};

	const EditMarkerModalSuccess = (data) => {
		setEditMarkerOpened(false);
		editPlotlineHandler({
			param: data?.action === "edit" ? "onEditMarker" : "onDeleteMarker",
			selectedLabelText: data.event_type,
			selectedPlot: data.selectedPlot,
			selectedLabel: data.selectedLabel,
		});
	};

	const showEditorModal = () => {
		setIsEditorModalVisible(true);
	};

	const handleEditorModalOk = () => {
		setIsEditorModalVisible(false);
	};

	const handleEditorModalCancel = () => {
		setIsEditorModalVisible(false);
	};

	return {
        hasEditor,
		currentData,
        setCurrentData,
		scroll,
		enableCursor,
		loading,
		name,
		desc,
		open,
		lineIndex,
		confirmLoading,
		newMarkerOpened,
		editMarkerOpened,
		setLineIndex,
		setEnableCursor,
		setNewMarkerOpened,
		setEditMarkerOpened,
		handleOk,
		handleCancel,
		showModal,
		handleUpdateOk,
		handleUpdateCancelModal,
		handleEditeOk,
		handleEditCancleModal,
		AddNewMarker,
		EditMarkerModalSuccess,
		editPlotlineHandler,
		isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
		errorMessage
	};
};

export { defaultData, usePlotlineDetail};