    // Start of Selection
    import { useState, useEffect, useRef, useContext } from "react";
    import { useDispatch, useSelector } from "react-redux";
    import Lines from "../../assets/icons/Lines.svg";
    import CreateBookIcon from "../../assets/icons/create-book.svg";
    import CurrentCard from "../../assets/icons/Onboarding/selected-onboarding.svg";
    import OtherCard from "../../assets/icons/Onboarding/unselected-onboarding.svg";
    import UnSelectedHome from "../../assets/icons/SidebarIcon/Books.svg";
    import { ACTIVITY_LOGS, ONBOARDING_CONTENT } from "../../constant/constant";
    import { getSeriesData, postSeriesData } from "../../redux/Slice/SeriesSlice";
    import { getBookData, postBookData } from "../../redux/Slice/BookSlice";
    import { getColorData } from "../../redux/Slice/ColorSlice";
    import { getDashboard } from "../../redux/Slice/dashboard";
    import CustomButton from "../../components/button/Button";
    import { ActivityLog } from "../../components/cards/ActivityLog";
    import SeriesCards from "../../components/cards/Series";
    import { useNavigate } from "react-router-dom";
    import Books, { ActivityList } from "../../components/cards/Books";
    import PackageSubscriptionHook, {
    	mapFeaturesToButtons,
    } from "../../components/PackageSubscriptionHook";
    import { HeaderDataContext } from "../../context/HeaderContext";
    import { Spin } from "antd";
    import { apiServices } from "../../services/apiServices/Api";
    import { DashboardActivityLog } from "../../components/cards/DashboardActivityLog";
    
    const useDashboard = () => {
    	const header = useContext(HeaderDataContext);
    	const navigate = useNavigate();
    	const dispatch = useDispatch();
    	const ref = useRef();
    
    	const [isModalVisible, setModalVisible] = useState(true);
    	const [showModal, setShowModal] = useState(false);
    	const [currentPage, setCurrentPage] = useState(0);
    	const [createBook, setCreateNewBook] = useState(false);
    	const [cardStatus, setCardStatus] = useState(1);
    	const [select, setSelect] = useState("Activity Dashboard");
    	const [subscriptionModal, setSubscriptionModal] = useState(false);
    	const [controlledButtons, setControlledButtons] = useState({
    		createSeries: false,
    		createBook: false,
    		feature: "",
    	});
    	const [clickedButton, setClickedButton] = useState([]);
    	const [activityLogs, setActivityLogs] = useState([]);
    	const [loading, setLoading] = useState(true);
    
    	const { user } = useSelector((state) => state?.auth);
    	const { count } = useSelector((state) => state?.words);
    	const { error } = useSelector((state) => state.books);
    	const { task_count, calender_count, totalBooks, totalSeries } = useSelector(
    		(state) => state.dashboard
    	);
    	const { seriesData } = useSelector((state) => state.series);
    	const { book_data } = useSelector((state) => state.books);
    
    	const user_name = user[0]?.user?.name;
    
    	// Set the header title inside useEffect to prevent state updates during rendering
    	useEffect(() => {
    		header.setHeading("Dashboard");
    	}, [header]);
    
    	// Fetch activity logs when the component mounts or user changes
    	useEffect(() => {
    		const fetchLogs = async () => {
    			if (user[0]?.user?.id) {
    				await fetchActivityLogs(user[0].user.id);
    			} else {
    				setLoading(false);
    				console.error("User ID is undefined.");
    			}
    		};
    		setLoading(true);
    		fetchLogs();
    	}, [user]);

		const hasRunRef = useRef(false);
		if (!hasRunRef.current) {
			dispatch(getSeriesData());
			dispatch(getBookData());
			hasRunRef.current = true;
		}
    
    	const fetchActivityLogs = async (userId) => {
    		try {
    			const response = await apiServices.get(`/logs/list/${userId}`, {
    				headers: {
    					"Content-Type": "application/json",
    					Accept: "application/json",
    					Authorization: `Bearer ${localStorage.getItem("token")}`,
    				},
    			});
    			if (response.data) {
    				setActivityLogs(response.data);
    			} else {
    				console.error("Empty response data or invalid structure:", response);
    			}
    		} catch (err) {
    			console.error("Error fetching recent updates:", err);
    		} finally {
    			setLoading(false);
    		}
    	};
    
    	// Dispatch dashboard-related actions on mount
    	useEffect(() => {
    		Promise.all([
    			dispatch(getDashboard()),
    			// dispatch(getSeriesData()),
    			// dispatch(getBookData()),
    			dispatch(getColorData())
    		])
    		.then(() => {
    			console.log("All actions dispatched successfully");
    		})
    		.catch((error) => {
    			console.error("Error dispatching actions:", error);
    		});
    
    		const modalShown = localStorage.getItem("isModalShown");
    		console.log(modalShown);
    		if (modalShown === "true") {
    			setShowModal(true);
    			localStorage.setItem("isModalShown", "false");
    		}
    	}, []);
    
    	const createNewBookDrawer = (status) => {
    		setCardStatus(status);
    		setCreateNewBook(true);
			console.log(status, status === 1 ? "All Series" : "All Books");
    		setSelect(status === 1 ? "All Series" : "All Books");
    	};
    
    	const closeCreateBookDrawer = () => {
    		setCreateNewBook(false);
    	};
    
    	const onAddData = async (data) => {
    		try {
    			if (cardStatus === 1) {
    				await dispatch(postSeriesData({ data }));
    				// await dispatch(getSeriesData());
    			} else {
    				await dispatch(postBookData({ data }));
    				// await dispatch(getBookData());
    			}
    			closeCreateBookDrawer();
    			return Promise.resolve(true);
    		} catch (e) {
    			console.log("error", e);
    			return Promise.reject(e);
    		}
    	};
    
    	const checkSubscription = (action) => {
    		const featureMap = {
    			"series-feature": "createSeries",
    			"full-planning-features-for-a-new-book": "createBook",
    		};
    		const updatedButtons = mapFeaturesToButtons(featureMap, action);
    		setControlledButtons(updatedButtons);
    	};
    
    	const openSubscriptionModal = () => {
    		setSubscriptionModal(true);
    	};
    
    	const booksContentSection = () => (
    		<>
    			<h2 className="sub-title mb-3 bcs-heading">Start something new:</h2>
    			<div className="acl-btn-flex">
    				<PackageSubscriptionHook
    					feature={["full-planning-features-for-a-new-book", "series-feature"]}
    					ref={ref}
    					title="Multiple Features"
    					checkPackageSubscription={checkSubscription}
    				/>
    				<CustomButton
    					buttonTitle={"Create New Series"}
    					type={"primary"}
    					onClick={() =>
    						controlledButtons.createSeries
    							? createNewBookDrawer(1)
    							: (setClickedButton([{ feature: "series-feature" }]),
    							  openSubscriptionModal())
    					}
    					icon={<img src={CreateBookIcon} alt="Button Icon" />}
    				/>
    				<CustomButton
    					buttonTitle={"Create New Book"}
    					onClick={() =>
    						controlledButtons.createBook
    							? createNewBookDrawer(2)
    							: (setClickedButton([
    									{ feature: "full-planning-features-for-a-new-book" },
    							  ]),
    							  openSubscriptionModal())
    					}
    				/>
    			</div>
    			<div className="mb-4">
				<div className="display-flex space-between acl-pkg">
    					<h2 className="sub-title">
    						{select === "Activity Dashboard"
    							? "Pick up where you left off:"
    							: select && `Your ${select}`}
    					</h2>
    					{select === "Activity Dashboard" && (
    						<p className="text-16 font-w-500 light-grey">Recent Updates</p>
    					)}
    				</div>
    			</div>
    			{(() => {
    				switch (select) {
    					case "All Books":
							console.log('rendered by book_data');
    						return (
    							<Books
    								disable={!controlledButtons.createBook}
    								openSubscriptionModal={openSubscriptionModal}
    							/>
    						);
    					case "All Series":
    						return (
    							<SeriesCards
    								disable={!controlledButtons.createSeries}
    								openSubscriptionModal={openSubscriptionModal}
    							/>
    						);
    					default:
    						return <ActivityList />;
    				}
    			})()}
    		</>
    	);
    
    	const modalContent = (content) => {
    		const { title, mainContent, icon, animation } = content;
    		const btnCondition = currentPage < 5;
    		return (
    			<div className={`text-align-center ${animation}`}>
    				<div className="text-34 font-w-700 black-color mt-0-3 mb-1">
    					{title}
    				</div>
    				<div className={`${content?.style ?? "mh-240px"} black-color`}>
    					<div className="onboarding-text mb-3">{mainContent}</div>
    					{content?.subContents && (
    						<div className="mb-3">
    							{content.subContents?.map((item, index) => (
    								<div className="onboarding-text mb-0-3" key={index}>
    									{item}
    								</div>
    							))}
    						</div>
    					)}
    				</div>
    				{icon.map((item, index) => (
    					<div className="mb-3" key={index}>
    						<img src={item} alt="Onboarding Icon" />
    					</div>
    				))}
    				{content?.textIcons?.map((item, index) => (
    					<img src={item} alt="text icon" key={index} />
    				))}
    				<div className="display-flex justify-center  mt-2 mb-4">
    					{content?.postIcons?.map((item, index) => (
    						<img
    							src={item}
    							alt="post icon"
    							key={index}
    							className="mh-10px shadow"
    						/>
    					))}
    				</div>
    				<CustomButton
    					buttonTitle={btnCondition ? "Next" : "Finish"}
    					spacing="margin-0"
    					verticalSpacing="mb-3"
    					type="success"
    					onClick={() => {
    						btnCondition
    							? setCurrentPage((prev) => prev + 1)
    							: setModalVisible(false);
    					}}
    				/>
    				<div
    					className="text-12 font-w-600 underline mb-4 cursor"
    					onClick={() => {
    						setModalVisible(false);
    					}}
    				>
    					Skip This
    				</div>
    				{ONBOARDING_CONTENT.map((_, index) => (
    					<img
    						key={index}
    						src={currentPage === index ? CurrentCard : OtherCard}
    						className={`${index !== 0 ? "ml-4" : ""} cursor`}
    						alt="onboarding tabs"
    						onClick={() => setCurrentPage(index)}
    					/>
    				))}
    			</div>
    		);
    	};
    
    	const activityLogSection = () => (
    		<>
    			<div className="display-flex space-between ml-3 mr-3">
    				<h2 className="sub-title">Activity log</h2>
    				<h2
    					className="text-16 font-w-500 light-grey cursor"
    					onClick={() => navigate("/activity-details")}
    				>
    					View All
    				</h2>
    			</div>
    			{loading ? (
    				<div className="display-flex center" style={{ minHeight: "100px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    					<Spin size="large" />
    				</div>
    			) : activityLogs.length > 0 ? (
    				activityLogs.map((item) => (
    					<DashboardActivityLog logsData={item} key={item.id || item.timestamp} />
    				))
    			) : (
    				<div className="no-activity-message" style={{ padding: "20px 34px" }}>
    					<p>No activity logs available.</p>
    				</div>
    			)}
    		</>
    	);
    
    	const options = [
    		{ value: "Activity Dashboard", text: "Activity Dashboard" },
    		{ value: "All Series", text: "All Series" },
    		{ value: "All Books", text: "All Books" },
    	];
    
    	const cards = [
    		{
    			icon: UnSelectedHome,
    			header: `${totalSeries ?? 0} Series`,
    			text: "Number of Series",
    		},
    		{
    			icon: UnSelectedHome,
    			header: `${totalBooks ?? 0} Books`,
    			text: "Number of Books",
    		},
    		{
    			icon: Lines,
    			header: `${count ?? 0}`,
    			text: "Words written today",
    			trend: "44",
    		},
    	];
    
    	return {
    		header,
    		navigate,
    		isModalVisible,
    		setModalVisible,
    		showModal,
    		setShowModal,
    		currentPage,
    		setCurrentPage,
    		createBook,
    		setCreateNewBook,
    		cardStatus,
    		setCardStatus,
    		select,
    		setSelect,
    		subscriptionModal,
    		setSubscriptionModal,
    		controlledButtons,
    		setControlledButtons,
    		clickedButton,
    		setClickedButton,
    		user,
    		count,
    		error,
    		task_count,
    		calender_count,
    		seriesData,
    		book_data,
    		user_name,
    		dispatch,
    		ref,
    		createNewBookDrawer,
    		closeCreateBookDrawer,
    		onAddData,
    		checkSubscription,
    		openSubscriptionModal,
    		booksContentSection,
    		modalContent,
    		activityLogSection,
    		options,
    		cards,
    	};
    };
    
    export { useDashboard };
