import React, { useEffect, useState } from "react";
import { Avatar, Input, Spin } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";
import ContactList from "./ContactList";
import { useDispatch, useSelector } from "react-redux";
import { getContacts } from "../../../../../redux/Slice/ChatSlice";

const ContactArea = ({ handleIsLeftModal, onlineUsers, styles }) => {
	const dispatch = useDispatch();
	const chats = useSelector((state) => state.chat.chats);
	const user = useSelector((state) => state.auth.user);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [isItemSearch, setIsItemSearch] = useState(false);
	const [orderedChats, setOrderedChats] = useState([]);
	const [filteredContacts, setFilteredContacts] = useState([]);

	useEffect(() => {
		const fetchContacts = async () => {
			setLoading(true);
			await dispatch(getContacts());
			setLoading(false);
		};
		fetchContacts();
	}, []);

	useEffect(() => {
		if (chats[0] && chats[0].length > 0) {
			const sortedChats = [...chats[0]].sort((a, b) => {
				const timeA = new Date(a.latest_chat?.message_time?.timestamp);
				const timeB = new Date(b.latest_chat?.message_time?.timestamp);
				return timeA > timeB ? 1 : -1;
			});
			setOrderedChats(sortedChats.reverse());
		} else {
			setOrderedChats([]);
		}
	}, [chats]);

	useEffect(() => {
		if (searchTerm.length === 0) {
			setFilteredContacts(chats[0] || []);
			setIsItemSearch(false);
		} else {
			const filtered = chats[0]?.filter(chat => chat.contact.name?.toLowerCase().includes(searchTerm.toLowerCase())) || [];
			setFilteredContacts(filtered);
			setIsItemSearch(true);
		}
	}, [searchTerm, chats]);

	const handleSearch = (e) => {
		setSearchTerm(e.target.value);
	};

	return (
		<div className={styles.contactArea}>
			<div className={styles.contactsHeader}>
				<Avatar
					key={user?.id}
					size="default"
					src={user[0].user.profile_image}
					icon={!user?.image && <UserOutlined />}
				/>
				<Input 
					placeholder="Search" 
					onChange={handleSearch} 
					prefix={<SearchOutlined />} 
					className={styles.searchInput}
				/>
			</div>
			{loading ? (
				<div className={styles.loadingContainer} class="pt-4" style={{width: '100%', display: "flex", justifyContent: 'center', alignItems: "center"}}>
					<Spin size="large" />
				</div>
			) : (
				<ContactList 
					contacts={filteredContacts.length > 0 && isItemSearch ? filteredContacts : orderedChats} 
					onlineUsers={onlineUsers} 
					styles={styles} 
				/>
			)}
		</div>
	);
};

export default ContactArea;
