import React, { useEffect, useState } from "react";
import { Col, Row, Pagination, Select, Typography, Card, Spin } from "antd";
import { DetailedActivityLog } from "../../components/cards/ActivityLog";
import { ACTIVITY_LOGS } from "../../constant/constant";
import { useSelector } from "react-redux";
import { apiServices } from "../../services/apiServices/Api";
import { DashboardActivityLog } from "../../components/cards/DashboardActivityLog";

const { Option } = Select;
const { Title } = Typography;
const ITEMS_PER_PAGE = 5;

const ActivityDetails = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [filter, setFilter] = useState("all");

	const [activityLogs, setActivityLogs] = useState([]);
	const [itemsPerPage] = useState(10);
	const [totalPages, setTotalPages] = useState(1);
	const [loading, setLoading] = useState(true);
	const { user } = useSelector((state) => state?.auth);
	const [filteredActivityLogs, setFilteredActivityLogs] = useState([]);

	useEffect(() => {
		setLoading(true);
		fetchActivityLogs(currentPage);
	}, [currentPage]);

	const fetchActivityLogs = async (page) => {
		try {
			const response = await apiServices.get(
				`/logs/list/${user[0]?.user?.id}?page=${page}`,
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);

			if (response.data) {
				setLoading(false);
				setActivityLogs(response.data);
				setTotalPages(response.meta.last_page);
			} else {
				console.error("Empty response data or invalid structure:", response);
			}
		} catch (err) {
			console.error("Error fetching recent updates:", err);
		} finally {
			setLoading(false);
		}
	};

	const handlePageChange = (pageNumber) => {
		setCurrentPage(pageNumber);
	};

	const handleFilterChange = (value) => {
		setFilter(value);
	};

	useEffect(() => {
		filterActivityLogs();
	}, [filter, activityLogs]);

	const filterActivityLogs = () => {
		let filteredLogs = [];
		if (filter === "all") {
			filteredLogs = activityLogs;
		} else if (filter === "Completed") {
			filteredLogs = activityLogs.filter((log) => log.status === "Completed"); // Filter logs with status "Completed"
		} else if (filter === "In Progress") {
			filteredLogs = activityLogs.filter((log) => log.status === "In Progress"); // Filter logs with status "In Progress"
		}
		setFilteredActivityLogs(filteredLogs);
	};

	return (
		<div
			className="activity-details-container"
			style={{ padding: "20px", width: "100%" }}
		>
			<Row justify="center">
				<Col xs={24} sm={24} md={22} lg={20} xl={18} xxl={16}>
					<Card className="activity-details-card" style={{ padding: "20px" }}>
						<div
							className="header-container header-new-cont"
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: "20px",
							}}
						>
							<Title level={2} className="activity-details-title">
								Activity Details
							</Title>
							<Select
								defaultValue="all"
								style={{ width: "200px" }}
								onChange={handleFilterChange}
								className="filter-select"
							>
								<Option value="all">All</Option>
								<Option value="Completed">Finished</Option>
								<Option value="In Progress">In Progress</Option>
							</Select>
						</div>
						<div className="activity-logs" style={{ marginBottom: "20px" }}>
							{loading ? (
								<Spin
									tip="Loading"
									size="large"
									className="display-flex center"
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										minHeight: "100px",
									}}
								></Spin>
							) : filteredActivityLogs.length === 0 ? (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										minHeight: "100px",
										fontSize: "16px",
										color: "#888",
									}}
								>
									No activity logs found.
								</div>
							) : (
								filteredActivityLogs.map((log, index) => (
									<DashboardActivityLog
										logsData={filteredActivityLogs[index]}
									/>
								))
							)}
						</div>
						{totalPages > 1 && (
							<Pagination
								current={currentPage}
								pageSize={itemsPerPage}
								total={totalPages * itemsPerPage}
								onChange={handlePageChange}
								showSizeChanger={false}
								rootClassName="list-pagination"
							/>
						)}
						{/* <div className="activity-logs" style={{ marginBottom: "20px" }}>
              {paginatedActivityDetails.map((log, index) => (
                <DetailedActivityLog
                  key={index}
                  date={log.date}
                  finished={log.finished}
                  inProgress={log.inProgress}
                  books={log.books}
                  series={log.series}
                  style={log.style}
                  outlineDetails={log.outlineDetails || "No outline details"}
                  plotlineDetails={log.plotlineDetails || "No plotline details"}
                  timelineDetails={log.timelineDetails || "No timeline details"}
                  brainstormDetails={
                    log.brainstormDetails || "No brainstorm details"
                  }
                  otherDetails={log.otherDetails || "No other details"}
                />
              ))}
            </div> */}
						{/* <Pagination
              current={currentPage}
              pageSize={ITEMS_PER_PAGE}
              total={filteredActivityDetails.length}
              onChange={handlePageChange}
              className="pagination"
              style={{ textAlign: "center" }}
            /> */}
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ActivityDetails;
