import React, { useState, useEffect } from "react";

import "./index.scss";
import {
	deleteSeriesData,
	getSeriesData,
	setSeriesSelice,
	updateSeries,
} from "../../redux/Slice/SeriesSlice";
import { useDispatch, useSelector } from "react-redux";
import { UpdateSeriesDrawer } from "../UpdateSeriesDrawer";
import SeriesListing from "../SeriesListing";
import { useLocation } from "react-router-dom";
import { Pagination, Spin } from "antd";
import { CommentsDrawer } from "../comments";
import { axiosInstance } from "../../services/apiServices/config";

const Content = ({ id, close, disable, openSubscriptionModal }) => {
	const [createBook, setCreateNewBook] = useState(false);
	const [cardStatus, setCardStatus] = useState(1);
	const dispatch = useDispatch();

	const onAddData = (data) => {
		try {
			const payload = { id: id, data: data };
			dispatch(updateSeries(payload)).then(() => {
				closeDrawer();
			});
		} catch (e) {
			console.log(e);
		}
	};
	const deleteSeries = (sid) => {
		dispatch(deleteSeriesData(sid));
	};
	const openDrawer = (status) => {
		setCardStatus(status);
		setCreateNewBook(true);
	};
	const closeDrawer = () => {
		setCreateNewBook(false);
	};

	return (
		<div>
			<div className="edit">
				<button
					onClick={() => {
						if (!disable) {
							close();
							openDrawer();
						} else {
							openSubscriptionModal();
						}
					}}
				>
					Edit
					<span className="ml-2">
						<i className="fa fa-check"></i>
					</span>
				</button>
			</div>

			<div className="delete-btn">
				<button
					onClick={() => {
						if (!disable) {
							deleteSeries(id);
							close();
						} else {
							openSubscriptionModal();
						}
					}}
				>
					Delete
					<span className="ml-2">
						<i className="fa fa-times"></i>
					</span>
				</button>
			</div>
			<UpdateSeriesDrawer
				status={cardStatus}
				open={createBook}
				onSubmit={onAddData}
				close={closeDrawer}
				id={id}
			/>
		</div>
	);
};

const SeriesCards = ({ disable = false, openSubscriptionModal = () => { } }) => {
	const dispatch = useDispatch();

	const { loading, seriesData } = useSelector((state) => state.series);
	const [openIndex, setOpenIndex] = useState(false);
	const [popupVisible, setPopupVisible] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(5);

	const location = useLocation();
	console.log(seriesData, 'seriesData');

	const close = () => {
		setPopupVisible(false);
	};

	const open = (index) => {
		setPopupVisible(true);
		setOpenIndex(index);
	};
	const handleOpenChange = (newOpen) => {
		setPopupVisible(newOpen);
	};
	useEffect(() => {
		let wait = false;
		if (!loading && !wait) {
			dispatch(getSeriesData());
		}
		return () => {
			wait = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentSeries = Array.isArray(seriesData[0]) ? seriesData[0]?.slice(indexOfFirstItem, indexOfLastItem) : [];

	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({ isOpen: false, id: null });
	const [commentsState, setCommentsState] = useState({});
	const [newCommentState, setNewCommentState] = useState("");
  
	const showCommentDrawer = (comments, id) => {
	  setCommentsState(prevState => ({
		...prevState,
		[id]: comments.map(i => ({ author: 'you', comment: i.comment }))
	  }));
	  setIsCommentDrawerOpen({ id, isOpen: true });
	};
  
	const handleCommentDrawerClose = () => {
	  setIsCommentDrawerOpen({ isOpen: false, id: null });
	};
  
	const handleAddComment = async () => {
	  if (newCommentState.trim()) {
		const postComments = await axiosInstance.post('/comments', {
		  comment_type: "series",
		  comment: newCommentState,
		  series_id: isCommentDrawerOpen.id
		});
		if (postComments.data) {
		  const item = seriesData[0].find(i => i.id == isCommentDrawerOpen.id);
		  if (item) {
			const updatedComments = [...item.comments, { id: item.comments.length + 1, author: 'You', comment: newCommentState }];
			const updatedItem = { ...item, comments: updatedComments };
			const updatedOutlineData = seriesData[0].map(i => i.id === isCommentDrawerOpen.id ? updatedItem : i);
			console.log(updatedOutlineData, 'log')
			dispatch(setSeriesSelice(updatedOutlineData));
		  }
		  setCommentsState(prevState => ({
			...prevState,
			[isCommentDrawerOpen.id]: [...prevState[isCommentDrawerOpen.id], { id: prevState[isCommentDrawerOpen.id].length + 1, author: 'You', comment: newCommentState }]
		  }));
		  setNewCommentState("");
		}
	  }
	};

	return (
		<div>
			<div className="card-container mt-2">
				<div className="p-3">
					{seriesData[0]?.length === 0 && !loading ? (
						<h4 style={{ textAlign: "center" }}>
							No Series to display, Click on Create New Series to add New Series
						</h4>
					) : (
						currentSeries?.length > 0 &&
						currentSeries.map((series) => {
							return (
								<SeriesListing
									key={series.id}
									style={{
										backgroundColor: series.color_code,
										color: series.foreground_color,
										cursor: "pointer",
									}}
									foreground_color={series.foreground_color}
									color_code={series.color_code}
									id={series.id}
									name={series.series_name}
									description={series.series_description}
									content={<Content id={series.id} close={close} disable={disable} openSubscriptionModal={openSubscriptionModal} />}
									pathname={location.pathname}
									updated={series.updated_at}
									icon={series.image_url}
									open={() => {
										open(series.id);
									}}
									openIndex={openIndex}
									popupVisible={popupVisible}
									handleOpenChange={handleOpenChange}
									showCommentDrawer={()=> showCommentDrawer(series.comments, series.id)}
									comments={series.comments}
								/>
							);
						})
					)}
					{seriesData[0]?.length > itemsPerPage && (
						<div style={{ float: "right" }}>
							<Pagination
								current={currentPage}
								pageSize={itemsPerPage}
								total={seriesData[0].length}
								onChange={paginate}
								showSizeChanger={false}
								className="pagination"
							/>
						</div>
					)}
				</div>
			</div>
			<CommentsDrawer
				mod="auther"
				hasEditor={true}
				isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
				handleCommentDrawerClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				handleAddComment={handleAddComment}
			/>
		</div>
	);
};

export default SeriesCards;
