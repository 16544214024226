import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";
//post
export const postBookData = createAsyncThunk("books/postBookData", (books) => {
  const booksData = apiServices
    .post(`/books`, books.data)
    .then((response) => response.data);
  return booksData;
});
//get books data
export const getBookData = createAsyncThunk("books/getBookData", async ({page = 1}) => {
  let totalPages;
  let books;
  const response = await apiServices.get(`/books-list?page=${page}`);
  totalPages = response.meta.last_page;
  let result = response.data.map((obj) => ({
    ...obj,
    value: obj.id,
    label: obj.book_name,
  }));
  books = result;
  return { data: books, totalPages: totalPages };
});
//delete a boook
export const deleteBooksData = createAsyncThunk(
  "books/deleteBooksData",
  (id) => {
    const deleteBook = apiServices
      .delete(`/books/${id}`)
      .then((response) => {
        if (response.success) {
          // message.success(response.message);
        } else {
          message.error(response.message);
        }
        return id;
      })
      .catch((err) => console.log(err));
    return deleteBook;
  }
);
export const updateBook = createAsyncThunk(
  "books/updateBook",
  ({ id, data }) => {
    const bookData = apiServices
      .update(`/books/${id}`, data)
      .then((response) => {
        // console.log(response);
        return response.data;
      })
      .catch((err) => err);
    return bookData;
  }
);
export const getRecentUpdates = createAsyncThunk(
  "books/getRecentUpdates",
  async (page) => {
    try {
      const response = await apiServices.get(`/recent-updates?page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response;
    } catch (err) {
      return err;
    }
  }
);
const initialState = {
  book_data: [],
  loading: false,
  totalPages: 1,
  error: false,
  recent_updates: [],
};
const BookSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    setEdit: (state, action) => {
      state.edit = action.payload.edit;
      state.body = action.payload.body;
    },
    bookSeliceReset: (state) => {
      Object.assign(state, initialState);
    },
    setBookSelice: (state, action) => {
      state.book_data = [action.payload]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postBookData.pending, (state) => {
        state.loading = true;
      })
      .addCase(postBookData.fulfilled, (state, action) => {
        state.loading = false;
        state.book_data[0].unshift(action.payload);
        message.success("book created successfully");
      })
      .addCase(postBookData.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
        message.error("book not created");
      })
      .addCase(getBookData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBookData.fulfilled, (state, action) => {
        state.loading = false;
        state.book_data = [action.payload.data];
        state.totalPages = action.payload.totalPages
      })
      .addCase(getBookData.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      })
      .addCase(deleteBooksData.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBooksData.fulfilled, (state, action) => {
        state.loading = false;
        state.book_data[0] = state.book_data[0].filter(book => book.id !== action.payload);
        message.success("book deleted successfully");
      })
      .addCase(deleteBooksData.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
        message.error("book not deleted");
      })
      .addCase(updateBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateBook.fulfilled, (state, action) => {
        state.loading = false;
        state.book_data[0] = state.book_data[0].map(book => book.id === action.payload.id ? action.payload : book);
        message.success("book updated successfully");
      })
      .addCase(updateBook.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
        message.error("book not updated");
      })
      .addCase(getRecentUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRecentUpdates.fulfilled, (state, action) => {
        state.loading = false;
        state.recent_updates = action.payload;
      })
      .addCase(getRecentUpdates.rejected, (state, action) => {
        state.loading = true;
        state.error = action.payload;
      });
  },
});
const BookReducer = BookSlice.reducer;
export const { setEdit, bookSeliceReset, setBookSelice } = BookSlice.actions;
export default BookReducer;