import React, { useState } from "react";
import Select from "react-select";
import CustomButton from "../button/Button";
import CreateBookIcon from "../../assets/icons/create-book.svg";
import TimelineCard from "./TimelineCard";
import { Pagination } from "antd";
import AddTimelineDrawer from "./AddTimelineDrawer";
import EditTimelineDrawer from "./EditTimelineDrawer";
import PackageSubscriptionHook from "../PackageSubscriptionHook";
import { SubscriptionModal } from "../subscriptionodal";
import { useTimelines } from "../../hooks/timelines/useTimelines";
import { setTimelinDataSelice } from "../../redux/Slice/TimelineSlice";
import { axiosInstance } from "../../services/apiServices/config";
import { CommentsDrawer } from "../comments";
import FullPageSpinner from "../loader/FullPageSpinner";

const Timelines = () => {
	const {
		ref,
		dispatch,
		token,
		header,
		id,
		loading,
		totalRecords,
		currentPage,
		timelines,
		timelineData,
		selectBook,
		selectedBook,
		showAddNewDrawer,
		showEditDrawer,
		selectedTimeline,
		books,
		subscriptionModal,
		controlledButtons,
		clickedButton,
		setTotalRecords,
		setCurrentPage,
		setTimelines,
		setSelectedBook,
		setShowAddNewDrawer,
		setShowEditDrawer,
		setSelectedTimeline,
		setBooks,
		setSubscriptionModal,
		setControlledButtons,
		setClickedButton,
		getTimelinesByBook,
		handlePageClick,
		editTimeline,
		deleteTimeline,
		checkSubscription,
		openSubscriptionModal,
	} = useTimelines();

	const TimelineDataToDisplay =
		selectedBook.value === "" ? timelineData : timelines;

	const [isCommentDrawerOpen, setIsCommentDrawerOpen] = useState({
		isOpen: false,
		id: null,
	});
	const [commentsState, setCommentsState] = useState({});
	const [newCommentState, setNewCommentState] = useState("");

	const showCommentDrawer = (comments, id) => {
		setCommentsState((prevState) => ({
			...prevState,
			[id]: comments.map((i) => ({ author: "you", comment: i.comment })),
		}));
		setIsCommentDrawerOpen({ id, isOpen: true });
	};

	const handleCommentDrawerClose = () => {
		setIsCommentDrawerOpen({ isOpen: false, id: null });
	};

	const handleAddComment = async () => {
		if (newCommentState.trim()) {
			const postComments = await axiosInstance.post("/comments", {
				comment_type: "plot_planner",
				comment: newCommentState,
				plot_planner_id: isCommentDrawerOpen.id,
			});
			if (postComments.data) {
				const item = timelines.find((i) => i.id == isCommentDrawerOpen.id);
				if (item) {
					const updatedComments = [
						...item.comments,
						{
							id: item.comments.length + 1,
							author: "You",
							comment: newCommentState,
						},
					];
					const updatedItem = { ...item, comments: updatedComments };
					const updatedplotlines = timelines.map((i) =>
						i.id === isCommentDrawerOpen.id ? updatedItem : i
					);
					dispatch(setTimelinDataSelice(updatedplotlines));
				}
				setCommentsState((prevState) => ({
					...prevState,
					[isCommentDrawerOpen.id]: [
						...prevState[isCommentDrawerOpen.id],
						{
							id: prevState[isCommentDrawerOpen.id].length + 1,
							author: "You",
							comment: newCommentState,
						},
					],
				}));
				setNewCommentState("");
			}
		}
	};
	return (
		<div className="main-container">
			<PackageSubscriptionHook
				feature={["timeline-feature"]}
				ref={ref}
				title="Timeline"
				checkPackageSubscription={checkSubscription}
			/>
			<div className="list-actions new-tm-add">
				<div className="addnew">
					<CustomButton
						type="primary"
						buttonTitle={"Add New Timeline"}
						onClick={() =>
							controlledButtons.createTimeline
								? setShowAddNewDrawer(true)
								: (setClickedButton([{ feature: "timeline-feature" }]),
								  openSubscriptionModal())
						}
						icon={<img src={CreateBookIcon} alt="Button Icon" />}
					/>
				</div>
				{books.length > 0 && (
					<div className="filter">
						<label>Select book: </label>
						<Select
							options={[selectBook, ...books]}
							onChange={getTimelinesByBook}
							isSearchable={true}
							className="select-book"
							value={selectedBook}
						/>
					</div>
				)}
			</div>
			<div className="card-container mt-2">
				<div className="p-3">
					{loading ? (
						<FullPageSpinner />
					) : TimelineDataToDisplay.length === 0 ? (
						<h4 style={{ textAlign: "center" }}>
							No Timeline to display, Click on Add New Timeline button to add
							new.
						</h4>
					) : (
						TimelineDataToDisplay.map((timeline) => (
							<TimelineCard
								key={timeline.id}
								style={{
									backgroundColor: timeline.color_code,
									color: timeline.foreground_color,
									cursor: "pointer",
								}}
								timeline={timeline}
								editTimeline={editTimeline}
								deleteTimeline={deleteTimeline}
								showCommentDrawer={() =>
									showCommentDrawer(timeline.comments, timeline.id)
								}
							/>
						))
					)}
				</div>
				<Pagination
					rootClassName="list-pagination"
					total={totalRecords}
					current={currentPage}
					onChange={handlePageClick}
				/>
			</div>
			<AddTimelineDrawer
				open={showAddNewDrawer}
				close={() => setShowAddNewDrawer(false)}
				books={books}
				getTimelinesByBook={getTimelinesByBook}
			/>
			<EditTimelineDrawer
				timeline={selectedTimeline}
				open={showEditDrawer}
				close={() => setShowEditDrawer(false)}
				books={books}
				getTimelinesByBook={getTimelinesByBook}
			/>
			<SubscriptionModal
				open={subscriptionModal}
				ok={openSubscriptionModal}
				features={clickedButton}
				cancel={() => setSubscriptionModal(false)}
			/>
			<CommentsDrawer
				mod="auther"
				hasEditor={true}
				isCommentDrawerOpen={isCommentDrawerOpen.isOpen}
				handleCommentDrawerClose={handleCommentDrawerClose}
				comments={commentsState[isCommentDrawerOpen.id] || []}
				newComment={newCommentState}
				setNewComment={setNewCommentState}
				handleAddComment={handleAddComment}
			/>
		</div>
	);
};

export default Timelines;
