    // Start of Selection
    import { Button, Form, Input, Modal, Select, Row } from "antd";
    import { useEffect, useState } from "react";
    
    const EditMarkerModal = ({ open, ok, cancel, onSubmit, plotLines }) => {
    	const [plot, setPlot] = useState([]);
    	const [action, setAction] = useState("edit");
    	const [marker, setMarker] = useState([]);
    
    	const [form] = Form.useForm();
    
    	const handleSubmit = (e) => {
    		let data = { ...e };
    		let result = plotLines.findIndex((item) => item.title === data.plotLine);
    		let label = marker.findIndex((item) => item.value === data.marker);
    		data.selectedPlot = result;
    		data.selectedLabel = label;
    		data.action = action;
    		onSubmit(data);
    	};
    
    	const onSelectPlot = (value) => {
    		let selectedPlot = plotLines.find((item) => item.title === value);
    		if (selectedPlot) {
    			let markers = selectedPlot.data.map((item, index) => ({
    				...item,
    				value: item.custom_label,
    				id: index,
    			}));
    			setMarker(markers);
    			form.setFieldsValue({
    				marker: "",
    				event_type: "",
    			});
    		}
    	};
    
    	const onSelectMarker = (value) => {
    		let selectedPlotLine = plotLines.find((plotLine) =>
    			plotLine.data.some((marker) => marker.custom_label === value)
    		);
    		if (selectedPlotLine) {
    			form.setFieldsValue({
    				plotLine: selectedPlotLine.title,
    				event_type: value,
    				marker: value,
    			});
    			let markers = selectedPlotLine.data.map((item, index) => ({
    				...item,
    				value: item.custom_label,
    				id: index,
    			}));
    			setMarker(markers);
    		}
    	};
    
    	useEffect(() => {
    		let plotOptions = plotLines?.map((item, index) => ({
    			...item,
    			value: item.title,
    			id: index,
    		}));
    		setPlot(plotOptions);
    		if (plotOptions.length > 0) {
    			let initialMarkers = plotOptions[0].data.map((item, index) => ({
    				...item,
    				value: item.custom_label,
    				id: index,
    			}));
    			setMarker(initialMarkers);
    		}
    	}, [plotLines]);
    
    	return (
    		<Modal
    			className="chapterModal"
    			title="Edit Marker"
    			open={open}
    			onOk={ok}
    			onCancel={cancel}
    			mask={false}
    			width={350}
    			style={{
    				marginLeft: "auto",
    				marginRight: "auto",
    				position: "relative",
    				marginTop: "12%",
    				padding: "1.2rem",
    				height: "20rem",
    			}}
    		>
    			<Form
    				layout="horizontal"
    				initialValues={{
    					remember: true,
    				}}
    				onFinish={handleSubmit}
    				form={form}
    				autoComplete="off"
    				className="gx-signin-form gx-form-row0"
    				style={{
    					maxWidth: 1000,
    				}}
    			>
    				<Form.Item
    					style={{ padding: ".5rem" }}
    					label="Name"
    					name="event_type"
    					labelCol={{ span: 24 }}
    					rules={[
    						{
    							required: action === "edit",
    							message: "Name is required",
    						},
    					]}
    				>
    					<Input />
    				</Form.Item>
    				<Form.Item
    					style={{ padding: ".5rem" }}
    					label="PlotLine"
    					name="plotLine"
    					labelCol={{ span: 24 }}
    					rules={[
    						{
    							required: true,
    							message: "PlotLine is required",
    						},
    					]}
    				>
    					<Select
    						onSelect={onSelectPlot}
    						options={plot}
    						placeholder="Select PlotLine"
    					/>
    				</Form.Item>
    				<Form.Item
    					style={{ padding: ".5rem" }}
    					label="Marker"
    					name="marker"
    					labelCol={{ span: 24 }}
    					rules={[
    						{
    							required: true,
    							message: "Marker is required",
    						},
    					]}
    				>
    					<Select
    						onSelect={onSelectMarker}
    						options={marker}
    						placeholder="Select Marker"
    					/>
    				</Form.Item>
    				<Row style={{ justifyContent: "space-between" }}>
    					<Form.Item style={{ padding: ".5rem" }}>
    						<Button
    							type="primary"
    							onClick={() => setAction("edit")}
    							htmlType="submit"
    						>
    							Edit
    						</Button>
    					</Form.Item>
    					<Form.Item style={{ padding: ".5rem" }}>
    						<Button
    							type="primary"
    							htmlType="submit"
    							onClick={() => setAction("delete")}
    						>
    							Delete
    						</Button>
    					</Form.Item>
    				</Row>
    			</Form>
    		</Modal>
    	);
    };
    
    export default EditMarkerModal;
