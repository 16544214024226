import React from "react";
import { Popover, Tag } from "antd";

import WebIcon from "../assets/icons/web-icon.svg";

import "./cards/index.scss";
import { getTimeDuration } from "../services/utilServices";
import { Link } from "react-router-dom";
import newBook from "../assets/images/newbook.png";
import { CommentOutlined, ShareAltOutlined } from "@ant-design/icons";
import { MenuButton } from "./button/MenuButton";

const SeriesListing = ({
	icon,
	name,
	description,
	updated,
	content,
	id,
	color_code,
	foreground_color,
	pathname,
	// book_id,
	// color_id,
	audio_file,
	// brainstorm_name,
	// image_id,
	open,
	openIndex,
	popupVisible,
	handleOpenChange,
	comments,
	showCommentDrawer,
}) => {
	return (
		<div
			className="book-card-container"
			id={id}
			style={{
				backgroundColor: color_code,
				color: foreground_color,
				cursor: "pointer",
			}}
		>
			<div className="display-flex space-between mobile-flex">
				<div>
					{/* <img src={Share} alt="Share Icon" /> */}
					<ShareAltOutlined
						className="mr-1 align-center"
						style={{
							color: foreground_color,
						}}
					/>
					<span
						className="thin-text-13 mr-2"
						style={{
							color: foreground_color,
						}}
					>
						Shared by:
					</span>
					<img src={WebIcon} className="mr-1" alt="Web Icon" />
					<span
						className="bold-text-13"
						style={{
							color: foreground_color,
						}}
					>
						Shana Tenerths
					</span>
				</div>
				<div>
					<CommentOutlined
						style={{
							color: foreground_color,
						}}
						className="mr-2"
						onClick={showCommentDrawer}
					/>
					{/* <img src={Message} className="mr-2" alt="Message Icon" /> */}
					<Tag className="bold-white-text-11" onClick={showCommentDrawer}>
						{comments.length ?? 0} Comment
					</Tag>
					<Popover
						popupVisible={false}
						placement="right"
						content={content}
						open={id === openIndex && popupVisible}
						title="Actions"
						trigger="click"
						style={{ cursor: "pointer" }}
						onOpenChange={handleOpenChange}
					>
						<MenuButton onClick={open} id={id} color={foreground_color} />
					</Popover>
				</div>
			</div>
			<Link
				to={{
					pathname: `/series/dashboard/${id}`,
				}}
			>
				<div className="display-flex mt-2 new-mt">
					<img
						src={icon?.length > 0 ? icon : newBook}
						className="mr-3 self-start"
						alt="Book or Series Icon"
						width="70"
						height="70"
					/>
					<div>
						<h2
							className="sub-title-16 font-w-800 mb-0-3"
							style={{
								color: foreground_color,
							}}
						>
							{name}
						</h2>
						<h4
							className="thin-text-13"
							style={{ lineHeight: 1.5, color: foreground_color }}
						>
							{description}
						</h4>
						<p>{audio_file}</p>
						<div className="display-flex mt-2">
							<p
								className="thin-text-13 mr-2"
								style={{
									color: foreground_color,
								}}
							>
								Updated: {getTimeDuration(updated)}
							</p>
							{/* <p
								className="thin-text-13 underline mr-2"
								style={{
									color: foreground_color,
								}}
							>
								Brainstorm
							</p>
							<p
								className="thin-text-13 underline mr-2"
								style={{
									color: foreground_color,
								}}
							>
								Outline
							</p>
							<p
								className="thin-text-13 underline mr-2"
								style={{
									color: foreground_color,
								}}
							>
								Plotline
							</p> */}
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

export default SeriesListing;
