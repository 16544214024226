import React, { useEffect, useState, useCallback } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import Authenticated from "./routes/authenticated";
import Unauthenticated from "./routes/Unauthenticated";
import FullPageSpinner from "./components/loader/FullPageSpinner";

import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import { useSelector, useDispatch } from "react-redux";
import { LogoutFunc, getCurrentUser } from "./redux/Slice/AuthSlice";
import HeaderContext from "./context/HeaderContext";
import AuthProvider from "./provider/AuthProvider";
import { useResetAllState } from "./helpers/resetAllState";

const App = () => {
	const { auth } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const [authenticated, setAuthenticated] = useState(auth);
	const { resetAll } = useResetAllState();

	const logout = async () => {
        try {
            if(auth){
                await dispatch(LogoutFunc()).then(async () => {
					await resetAll().then(() => {
						localStorage.removeItem("token");
						localStorage.removeItem("authenticated");
						window.location.reload()
					})
				})
            }
        } catch (err) {
            console.error(err);
        }
    };

	useEffect(() => {
		const checkAuthentication = async () => {
			await dispatch(getCurrentUser(logout));
		};
		checkAuthentication().then(()=> {
			if (auth && authenticated === null) {
				setAuthenticated(true);
			} else if (!auth && authenticated !== false) {
				setAuthenticated(false);
				logout()
			} else {
				setAuthenticated(auth)
			}
		})
	}, [auth]);

	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 1000 * 30, // 30 seconds
				refetchOnWindowFocus: false,
				retry: false,
			},
		},
	});

	if (authenticated === null) {
		return <FullPageSpinner />;
	}

	return (
		<React.Suspense fallback={<FullPageSpinner />}>
			<QueryClientProvider client={queryClient}>
				<BrowserRouter>
					<AuthProvider>
						<Routes>
							<Route path="/*" element={authenticated ? (
								<HeaderContext>
									<Authenticated isLoggedIn={authenticated} />
								</HeaderContext>
							) : (
								<Unauthenticated isLoggedIn={authenticated} />
							)} />
						</Routes>
					</AuthProvider>
				</BrowserRouter>
			</QueryClientProvider>
		</React.Suspense>
	);
};

export default App;
