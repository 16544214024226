import {
	Space,
	Col,
	Row,
	Input,
	Button,
	Form,
	Select,
	Modal,
	Popover,
	message,
} from "antd";
import React, { useState } from "react";

import OutlineChapterCard from "../../../components/cards/outline-cards/outline-chapter/OutlineChapterCard";
import face1 from "../../../assets/images/face-1.jpg";
import face2 from "../../../assets/images/face-2.jpg";
import face3 from "../../../assets/images/face-3.jpg";
import Menuu from "../../../assets/icons/menu.svg";
import OutlineSideModal from "../../../components/outline-side-modal/OutlineEventDrawer";
import EventItemCard from "../../../components/event-item-button/EventIem";
import BookNameOutline from "../../../components/cards/outline-cards/book-name-outline/BookNameOutlineCard";
import FullPageSpinner from "../../../components/loader/FullPageSpinner";

import {
	getChapters,
} from "../../../redux/Slice/ChapterSlice";
import { getOutline } from "../../../redux/Slice/OutlineSlice";
import { AUTHENTICATED_ROUTES } from "../../../constant/routeConstant";
import { axiosInstance } from "../../../services/apiServices/config";
import { useOutlineDetail } from "../../../hooks/outline/useOutlineDetial";
import OutlineEditContent from "../../../components/outlineEditContent";

const OutlineDetail = (props) => {
	const { mod = "auther" } = props;
	console.log(mod, 'mod')
	const {
		hasEditor,
		id,
		dispatch,
		isModalOpen,
		popupVisible,
		openIndex,
		title,
		name,
		desc,
		chapters,
		loading,
		chapterCards,
		colors,
		form,
		showModal,
		handleOk,
		handleCancel,
		SubmitChapters,
		close,
		open,
		isEditorModalVisible,
		showEditorModal,
		handleEditorModalOk,
		handleEditorModalCancel,
	} = useOutlineDetail({ mod });
	const [isSideModalOpen, setIsSideModalOpen] = useState(false);
	const [selectedChapterCardId, setSelectedChapterCardId] = useState(null);
	const [selectedChapterId, setSelectedChapterId] = useState(null);

	const EditCard = (data) => {
		return axiosInstance
			.put(`/chapters-cards/${data.id}`, data)
			.then((res) => {
				if (res.data) {
					message.success("successfully updated");
					dispatch(getChapters({ id }));
					// dispatch(getChapterCards({id}));
					dispatch(getOutline({ id }));
					close();
					return true;
				} else {
					message.error("something went wrong");
					return false;
				}
			})
			.catch((e) => {
				console.log(e);
				return false;
			});
	};

	const avatar = [
		{ id: 1, imgName: face1 },
		{ id: 2, imgName: face2 },
		{ id: 3, imgName: face3 },
	];

	return (
		<div className="main-container new-chg">
			<BookNameOutline
				link={AUTHENTICATED_ROUTES.OUT_LINE}
				name={loading ? "Loading Name..." : name}
				description={loading ? "Loading Description..." : desc}
				mod={mod}
			/>
			{loading ? (
				<FullPageSpinner />
			) : (
				<>
					<div style={{ display: "flex", justifyContent: "flex-end" }}>
					{mod !== "guest" && (
						<>
							<EventItemCard
								event_id={null}
								hasEditor={hasEditor && mod !== "guest"}
								chapters={chapters[0]?.data?.chapters?.map((c) => ({
									id: c.id, name: c.chapter_name, style: {
										width: "18px",
										height: "18px",
										background: c.color_code,
										borderRadius: "110px",
									}
								}))}
								showEditorModal={showEditorModal}
							/>
							{mod !== "guest" && (
								<>
									<Button
										onClick={
											hasEditor && mod !== "guest" ? showModal : showEditorModal
										}
										className="mt-3"
										style={{ marginLeft: "8px" }}
									>
										<Space>New Chapter</Space>
									</Button>
									<Modal
										className="chapterModal"
										title="Add New Chapter"
										open={isModalOpen}
										onOk={handleOk}
										onCancel={handleCancel}
										mask={false}
										width={350}
										style={{
											marginLeft: "auto",
											marginRight: "auto",
											position: "relative",
											marginTop: "12%",
											padding: "1.2rem",
											height: "20rem",
										}}
									>
										<Form
											layout="horizontal"
											initialValues={{ remember: true }}
											form={form}
											onFinish={SubmitChapters}
											autoComplete="off"
											className="gx-signin-form gx-form-row0"
											style={{ maxWidth: 1000 }}
										>
											<Form.Item
												style={{ padding: ".5rem" }}
												label="Chapter Name"
												name="chapter_name"
												labelCol={{ span: 24 }}
												rules={[{ required: true, message: "Name is required" }]}
											>
												<Input />
											</Form.Item>
											<Form.Item
												style={{ padding: ".5rem" }}
												label="Color"
												name="color_id"
												labelCol={{ span: 24 }}
												rules={[{ required: true, message: "Color is required" }]}
											>
												<Select options={colors} placeholder="Select Color" />
											</Form.Item>
											<Form.Item
												style={{ padding: ".5rem", textAlign: "center" }}
											>
												<Button type="primary" htmlType="submit">
													Add Chapter
												</Button>
											</Form.Item>
										</Form>
									</Modal>
								</>
							)}
						</>
					)}
					</div>
					<Row gutter={24}>
						{chapters[0]?.data?.chapters?.length > 0 &&
							chapters[0]?.data?.chapters?.map((c) => (
								<Col sm={12} xs={24} lg={6} md={12} xl={6} key={c.id}>
									<div
										className="chapter-name mt-4 mb-3"
										style={{ width: "100%" }}
									>
										<div
											style={{
												display: "flex",
												justifyContent: "space-between",
											}}
										>
											<div className="d-flex">
												<span
													style={{
														width: "18px",
														height: "18px",
														background: c.color_code,
														borderRadius: "110px",
													}}
													className="mr-2"
												></span>
												<p
													style={{ color: "black", fontWeight: "bold" }}
													id={c.id}
												>
													{c.chapter_name}
												</p>
											</div>

											{hasEditor && mod !== "guest" ? (
												<Popover
													open={c.id + "card" === openIndex && popupVisible}
													id={c.id}
													placement="right"
													content={
														<OutlineEditContent c_id={c.id} path_id={id} close={close} />
													}
													title="Actions"
													trigger="click"
													style={{ cursor: "pointer" }}
												>
													<img
														src={Menuu}
														alt="Menu Icon"
														id={c.id}
														style={{ cursor: "pointer" }}
														onClick={() => open(c.id + "card")}
													/>
												</Popover>
											) : (
												mod !== "guest" && (
													<img
														src={Menuu}
														alt="Menu Icon"
														id={c.id}
														style={{ cursor: "pointer" }}
														onClick={showEditorModal}
													/>
												)
											)}
										</div>
									</div>

									{c.cards?.map((card) => (
										<div key={card?.id}>
											{hasEditor && mod !== "guest" ? (
												<Popover
													open={card?.id === openIndex && popupVisible}
													content={
														<OutlineEditContent
															tasks={card?.tasks}
															close={close}
															c_id={card?.id}
															chapterId={c.id}
															path_id={id}
															setIsSideModalOpen={setIsSideModalOpen}
															setSelectedChapterCardId={setSelectedChapterCardId}
															setSelectedChapterId={setSelectedChapterId}
														/>
													}
													trigger="click"
													placement="right"
													style={{ cursor: "pointer" }}
												>
													{/* <FullPageSpinner /> */}
													<OutlineChapterCard
														id={card?.id}
														Data={card}
														open={() => open(card?.id) && close()}
														pill={card?.card_title}
														desc={card?.card_description}
														color={card?.foreground_color}
														tasks={card?.tasks}
														mod={mod}
														hasEditor={hasEditor}
														bgcolor={card?.color_code}
														sent="1"
														comments="5"
														avatar={avatar}
													/>
												</Popover>
											) : (
												<>
													{/* <FullPageSpinner /> */}
													<OutlineChapterCard
														id={card?.id}
														Data={card}
														open={() => open(card?.id) && close()}
														pill={card?.card_title}
														desc={card?.card_description}
														color={card?.foreground_color}
														tasks={card?.tasks}
														bgcolor={card?.color_code}
														sent="1"
														comments="5"
														avatar={avatar}
														showEditorModal={showEditorModal}
														hasEditor={hasEditor}
														mod={mod}
													/>
												</>
											)}
										</div>
									))}
								</Col>
							))}
					</Row>
				</>
			)}
			<Modal
				title="Access Denied"
				visible={isEditorModalVisible}
				onOk={handleEditorModalOk}
				onCancel={handleEditorModalCancel}
				centered
				footer={null}
			>
				<p>You do not have permission to perform this action.</p>
			</Modal>
			{mod !== "guest" && <OutlineSideModal
				open={isSideModalOpen}
				close={() => setIsSideModalOpen(false)}
				onsubmit={EditCard}
				chapterCardId={selectedChapterCardId}
				chapter_id={selectedChapterId}
			/>}
		</div>
	);
};

export default OutlineDetail;
