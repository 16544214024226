import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";
import "./index.scss";
import { Row, Col, Table, Button, Input, Dropdown, Menu, Modal, Select, Form, message } from "antd";
import { CiUser } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, searchUsers } from "../../../redux/Slice/UserSlice";
import FullPageSpinner from "../../../components/loader/FullPageSpinner";
import useDebounce from "./useDebounce";
import { HeaderDataContext } from "../../../context/HeaderContext";
import { debounce } from "lodash";
import { EllipsisOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../services/apiServices/config";
import { getTimelineList } from "../../../redux/Slice/TimelineSlice";
import { getPlotlineList } from "../../../redux/Slice/PlotlineSlice";
import { getChapters } from "../../../redux/Slice/ChapterSlice";
import { getOutlineList } from "../../../redux/Slice/OutlineSlice";
import { getBrainstorms } from "../../../helpers/GetList";
import { getBookData } from "../../../redux/Slice/BookSlice";

const UserRole = ({ role }) => (
    <div className="role-row" style={{ display: "flex", alignItems: "center", gap: "8px", padding: "8px" }}>
        {role === "Viewer" ? (
            <CiUser style={{ fontSize: "20px", color: "#3A5AFF" }} />
        ) : role === "Editor" ? (
            <MdEdit style={{ fontSize: "20px", color: "#00CFE8" }} />
        ) : null}
        <div style={{ fontWeight: "500" }}>{role}</div>
    </div>
);

const Settings = () => {
    const header = useContext(HeaderDataContext);
    header.setHeading("Settings");

    const [addUserModal, setAddUserModal] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 7,
        },
    });

    const handleTableChange = useCallback((pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter,
        });
    }, []);

    const handleRemove = async (email) => {
        await axiosInstance.delete(`/collaborators/${email}`).then((response) => {
            if (response.data.success) {
                dispatch(getUsers());
            }
        });
    };

    const columns = useMemo(() => [
        {
            title: "NAME",
            dataIndex: ["name"],
            render: (name, record) => (
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <img
                        className="image"
                        src={record?.profile_image || name?.charAt(0)}
                        alt=""
                        style={{ borderRadius: "50%", width: "40px", height: "40px" }}
                    />
                    <div>
                        <a className="name" href="/profile-settings" style={{ fontWeight: "bold", color: "#3A5AFF" }}>
                            {name}
                        </a>
                        <div className="tag" style={{ color: "#575A60" }}>{record?.tag}</div>
                    </div>
                </div>
            ),
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            title: "EMAIL",
            dataIndex: "email",
            render: (email) => <div style={{ color: "#575A60" }}>{email}</div>,
            sorter: (a, b) => a.name.length - b.name.length,
        },
        {
            key: "actions",
            render: (record) => (
                <Dropdown
                    overlay={(
                        <Menu>
                            <Menu.Item onClick={() => handleRemove(record.email)} key="2">Remove</Menu.Item>
                        </Menu>
                    )}
                    trigger={['click']}
                >
                    <Button type="text" icon={<EllipsisOutlined />} />
                </Dropdown>
            ),
        },
    ], [handleRemove]);

    const dispatch = useDispatch();
    const { users, loading, searchData } = useSelector((state) => state.user);
    const { book_data } = useSelector((state) => state.books);
    const { bstorm } = useSelector((state) => state.brainstorm);
    const { timelineData } = useSelector((state) => state.timeline);
    const { plotlines } = useSelector((state) => state.plotline);
    const { outlineData } = useSelector((state) => state.outline);

    const [searchTerm, setSearchTerm] = useState("");

    const debouncedSearch = useDebounce(searchTerm, 500);
    
    const [form] = Form.useForm();
    useEffect(() => {
        dispatch(getUsers());
        dispatch(getTimelineList());
        dispatch(getPlotlineList());
        dispatch(getOutlineList());
        dispatch(getBookData({ page: 1 }));
        getBrainstorms(null, 1, () => {}, () => {}, dispatch);


        if (debouncedSearch) {
            dispatch(searchUsers(debouncedSearch));
        }
    }, [debouncedSearch, dispatch]);

    const search = useCallback(
        debounce((e) => {
            e.preventDefault();
            setSearchTerm(e.target.value);
        }, 500),
        []
    );

    const handleAddUser = useCallback(async (values) => {
        const { users, type, itemId } = values;
        try {
            await Promise.all(users.map(async (email) => {
                await axiosInstance.post("/collaborators", {
                    email: [email],
                    collaboratable_id: [itemId],
                    collaboratable_type: selectedType + 's',
                    role: "editor"
                });
            }));
            message.success("User(s) invited successfully");
            setAddUserModal(false);
            setSelectedUsers([]);
            setSelectedType(null);
            setSelectedItemId(null);
            dispatch(getUsers());
        } catch (error) {
            message.error("Failed to invite user(s). Please try again.");
        } finally {
            form.resetFields();
        }
    }, [dispatch, selectedType, form]);

    console.log(selectedType, 'selectedType')
    const renderAdditionalOptions = useCallback((taskType) => {
        const options = {
            book: {
                label: "Book",
                name: "itemId",
                placeholder: "Select Book",
                options: book_data[0]?.map(book => ({ label: book.book_name, value: book.id })) || []
            },
            brainstorm: {
                label: "Brainstorm",
                name: "itemId",
                placeholder: "Select Brainstorm",
                options: bstorm?.map(b => ({ label: b.brainstorm_name, value: b.id })) || [],
            },
            timeline: {
                label: "Timeline",
                name: "itemId",
                placeholder: "Select Timeline",
                options: timelineData.map(timeline => ({ label: timeline?.name, value: timeline?.id })),
            },
            plotline: {
                label: "Plotline",
                name: "itemId",
                placeholder: "Select Plotline",
                options: plotlines.map(plotline => ({ label: plotline?.plot_planner_title, value: plotline?.id })),
            },
            outline: {
                label: "Outline",
                name: "itemId",
                placeholder: "Select Outline",
                options: outlineData.map(outline => ({ label: outline?.outline_title, value: outline?.id })),
            }
        };

        const option = options[taskType];
        if (!option) return null;

        return (
            <Form.Item
                label={option.label}
                name={option.name}
                labelCol={{ span: 24 }}
                rules={[{ required: true, message: `${option.label} is required` }]}
            >
                <Select options={option.options} placeholder={option.placeholder} />
            </Form.Item>
        );
    }, [book_data, bstorm, timelineData, plotlines, outlineData]);

    const closeModal = useCallback(() => {
        setAddUserModal(false);
        setSelectedUsers([]);
        setSelectedType(null);
        setSelectedItemId(null);
        form.resetFields();
    }, []);

    console.log(users[0].data)

    return (
        <div className="setting-container">
            <div className="setting-header">
                <Row className="header" span={24}>
                    <Col>
                        <h1>User Access</h1>
                    </Col>
                    <Col className="d-flex">
                        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                            <div style={{ fontSize: "18px", fontWeight: "400", color: "#575A60" }}>Search</div>
                            <Input
                                placeholder="Search user..."
                                type="text"
                                className="mr-2"
                                onChange={search}
                            />
                        </div>
                        <Button type="primary" onClick={() => setAddUserModal(true)}>Add New User</Button>
                    </Col>
                </Row>
            </div>

            {loading ? (
                <FullPageSpinner />
            ) : (
                <Table
                    className="access-table"
                    columns={columns}
                    dataSource={searchTerm.length > 1 ? searchData[0]?.data : users[0]?.data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}
                />
            )}
            <Modal
                title="Invite User"
                centered
                open={addUserModal}
                footer={null}
                confirmLoading={loading}
                onCancel={closeModal}
            >
                <Form layout="vertical" onFinish={handleAddUser} form={form}>
                    <Form.Item
                        label="Select Users"
                        name="users"
                        rules={[{ required: true, message: "Please select users" }]}
                    >
                        <Select
                            className="select-user"
                            mode="tags"
                            placeholder="Select users"
                            options={users[0].data.map((user) => ({
                                label: user.name,
                                value: user.email
                            }))}
                            onChange={setSelectedUsers}
                            value={selectedUsers}
                        />
                    </Form.Item>
                    {selectedUsers.length > 0 && (
                        <Form.Item
                            label="Select Type"
                            name="type"
                            rules={[{ required: true, message: "Please select a type" }]}
                        >
                            <Select
                                placeholder="Select type"
                                onChange={(value) => setSelectedType(value)}
                                value={selectedType}
                            >
                                <Select.Option value="book">Book</Select.Option>
                                <Select.Option value="brainstorm">Brainstorm</Select.Option>
                                <Select.Option value="timeline">Timeline</Select.Option>
                                <Select.Option value="plotline">Plotline</Select.Option>
                                <Select.Option value="outline">Outline</Select.Option>
                            </Select>
                        </Form.Item>
                    )}
                    {selectedType && renderAdditionalOptions(selectedType)}
                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            Add User
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Settings;
