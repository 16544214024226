import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import { apiServices } from "../../services/apiServices/Api";

//get chapters
export const getChapters = createAsyncThunk("chapters/getChapters", ({id, mod, guestData}) => {
	const chapters = apiServices
		.get(`/outlines/${id}${mod === 'guest' ? `?email=${guestData.email}&key=${guestData.key}` : ''}`)
		.then((response) => response);
	return chapters;
});

//post chapters
export const postChapters = createAsyncThunk(
	"chapters/postChapters",
	(chapter) => {
		const chapters = apiServices
			.post(`/chapters`, chapter.data)
			.then((response) => response.data)
			.catch((err) => console.log(err));
		return chapters;
	}
);
//delete chapters
export const deleteChapter = createAsyncThunk(
	"chapters/deleteChapter",
	(id) => {
		const chapters = apiServices
			.delete(`/chapters/${id}`)
			.then((response) => id)
			.catch((err) => console.log(err));

		// console.log("deleted chapter payload", chapters);
		return chapters;
	}
);

//update chapter
export const updateChapter = createAsyncThunk(
	"chapters/updateChapter",
	({ id, data }) => {
		const chapter = apiServices
			.update(`/chapters/${id}`, data)
			.then((response) => {
				return response;
			});

		return chapter;
	}
);
//get chapters list

export const getChaptersList = createAsyncThunk(
	"chapters/getChaptersList",
	() => {
		const chapters = apiServices.get(`/chapters`).then((response) => response);

		// console.log("all chapters", chapters);

		return chapters;
	}
);
const initialState = {
	chapters: [],
	loading: false,
	error: false,
	allChapters: [],
};

const ChapterSlice = createSlice({
	name: "chapters",
	initialState,
	reducers: {
		chapterSeliceReset : (state) => {
			Object.assign(state, initialState);
		},
		setLoading: (state, action) => {
			state.loading = action.payload
		},
		setChapterSelice: (state, action) => {
			state.chapters = action.payload
		}
	},
	extraReducers: (builder) => {
     builder.addCase(getChapters.pending, (state) => {
		state.loading = true;
	})
	.addCase(getChapters.fulfilled, (state, action) => {
		state.loading = false;
		state.chapters = [action.payload];
	})
	.addCase(getChapters.rejected, (state, action) => {
		state.loading = false;
		state.error = action.payload;
	})
	.addCase(getChaptersList.pending, (state) => {
		state.loading = true;
	})
	.addCase(getChaptersList.fulfilled, (state, action) => {
		state.loading = false;
		state.allChapters = [action.payload];
	})
	.addCase(getChaptersList.rejected, (state, action) => {
		state.loading = false;
		state.error = action.payload;
	})
	.addCase(postChapters.pending, (state) => {
		state.loading = true;
	})
	.addCase(postChapters.fulfilled, (state, action) => {
		state.loading = false;
		state.chapters[0].data.chapters.push(action.payload);
		message.success("Chapter Created successfully");
	})
	.addCase(postChapters.rejected, (state, action) => {
		state.loading = false;
		state.error = [action.payload];
		message.error("Chapter not created");
	})
	.addCase(deleteChapter.pending, (state) => {
		state.loading = true;
	})
	.addCase(deleteChapter.fulfilled, (state, action) => {
		state.loading = false;
		state.chapters[0].data.chapters = state.chapters[0].data.chapters.filter(chapter => chapter.id !== action.payload);
		message.success("chapter deleted successfully");
	})
	.addCase(deleteChapter.rejected, (state, action) => {
		state.loading = false;
		state.error = action.payload;
		message.error("chapter not deleted");
	})
	.addCase(updateChapter.pending, (state) => {
		state.loading = true;
	})
	.addCase(updateChapter.fulfilled, (state, action) => {
		state.loading = false;
		state.chapters = [...state.chapters, [action.payload]];
		message.success("chapter updated successfully");
	})
	.addCase(updateChapter.rejected, (state, action) => {
		state.loading = false;
		state.error = action.payload;
		message.error("chapter not updated"); // This should be message.error based on the context
	})
}
});

const ChapterReducer = ChapterSlice.reducer;
export const { chapterSeliceReset, setLoading, setChapterSelice } = ChapterSlice.actions;

export default ChapterReducer;
