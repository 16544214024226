import React from "react";
import { Avatar } from "antd";

import "./index.scss";
import { getTimeDuration } from "../../services/utilServices";

const DashboardActivityLog = ({ logsData }) => {
  const getStatusClass = (status) => {
    return status === "In Progress"
      ? "date-container"
      : "date-container-active";
  };

  return (
    <>
      <div className="activity-log-container">
        <div className="display-flex alc-mn " style={{ alignItems: "flex-start" }}>
          <div className="alc-fst"
            style={{
              padding: "10px",
              borderRadius: "8px",
              backgroundColor: logsData.status === "In Progress" ? "#f0f0f0" : "#d1e7dd",
              color: logsData.status === "In Progress" ? "#000" : "#0f5132",
              margin: "0 12px 0 0",
              textAlign: "center",
              minWidth: "80px",
            }}
          >
            <p style={{ fontSize: "10px", fontWeight: "600", margin: 0 }}>
              {logsData.status === "In Progress" ? "In Progress" : "Completed"}
            </p>
          </div>
          <div className="alc-mdl" style={{ marginRight: "12px" }}>
            <p className="text-13 font-w-600 blue-color mb-1">Action:</p>
            <p className="text-13 font-w-500 black-color mb-1">Card:</p>
            <p className="text-13 font-w-500 black-color mb-1">Book:</p>
            <p className="text-13 font-w-500 black-color">Time:</p>
          </div>
          <div className="alc-rt">
            <p className="text-13 font-w-600 blue-color mb-1">
              {logsData.action}
            </p>
            <p className="text-13 font-w-500 black-color mb-1">
              {logsData.cards.card_title}
            </p>
            <p className="text-13 font-w-500 black-color mb-1">
              {logsData.books.book_name}
            </p>
            <p className="text-13 font-w-500 black-color">
              {getTimeDuration(logsData.created_at)}
            </p>
          </div>
        </div>
      </div>

      <div className="divider" />
    </>
  );
};

const DetailedDashboardActivityLog = ({
  date,
  finished,
  inProgress,
  books,
  series,
  outlineDetails,
  plotlineDetails,
  timelineDetails,
  brainstormDetails,
  otherDetails,
  collaboratorRole,
  avatar,
  ...rest
}) => {
  const activityDetails = [
    { label: "Finished:", value: finished },
    { label: "In-Progress:", value: inProgress },
    { label: "WIP Book:", value: books },
    { label: "WIP Series:", value: series },
    { label: "Outline Details:", value: outlineDetails },
    { label: "Plotline Details:", value: plotlineDetails },
    { label: "Timeline Details:", value: timelineDetails },
    { label: "Brainstorm Details:", value: brainstormDetails },
    { label: "Other Details:", value: otherDetails },
  ];

  return (
    <div className="activity-log-container modern-design">
      <div
        className="header"
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <Avatar src={avatar} size={50} />
        <div className="header-text" style={{ marginLeft: "10px" }}>
          <p className="date text-14 font-w-600">{date}</p>
          <p className="collaborator-role text-12 font-w-400">
            {collaboratorRole}
          </p>
        </div>
      </div>
      <div className="activity-details">
        {activityDetails.map((detail, index) => (
          <div
            key={index}
            className="activity-item"
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            <p className="label text-13 font-w-500">{detail.label}</p>
            <p
              className={`value text-13 font-w-500 ${
                index === 0 ? "font-w-600 blue-color" : ""
              } ${rest?.style ?? ""}`}
            >
              {detail.value}
            </p>
          </div>
        ))}
      </div>
      <div className="divider" />
    </div>
  );
};
export { DashboardActivityLog, DetailedDashboardActivityLog };
