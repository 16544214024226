import { useDispatch } from "react-redux";
import { apiServices } from "../services/apiServices/Api";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { deleteChapterCard } from "../redux/Slice/ChapterCardsSlice";
import { getChapters } from "../redux/Slice/ChapterSlice";

const OutlineEditContent = ({
	c_id,
	close,
	tasks,
	chapterId,
	setIsSideModalOpen,
	setSelectedChapterCardId,
	setSelectedChapterId,
}) => {
	const [todos, setTodos] = useState([]);
	const { id } = useParams();
	const dispatch = useDispatch();

	const getTodos = async (id) => {
		try {
			const response = await apiServices.get(
				`${process.env.REACT_APP_API_URL}/card-tasks-list/${id}`,
				{}
			);
			const data = response.data;
			setTodos(data);
			return data;
		} catch (err) {
			console.log("Error while getting todo", err);
		}
	};

	const deletechapter = (cid) => {
		dispatch(deleteChapterCard(cid));
		setTimeout(() => {
			dispatch(getChapters({ id }));
		}, 1000);
		close();
	};

	const showModal = () => {
		setIsSideModalOpen(true);
		setSelectedChapterCardId(c_id);
		setSelectedChapterId(chapterId);
	};
	const handleCancel = () => setIsSideModalOpen(false);


	return (
		<div>
			<button
				className="edit p-1 px-5"
				style={{ border: "none" }}
				onClick={() => {
					showModal();
					close();
				}}
			>
				Edit
			</button>
			<div className="delete-btn p-1">
				<button onClick={() => deletechapter(c_id)}>Delete</button>
			</div>
		</div>
	);
};

export default OutlineEditContent;
