import { Form, Input, Card, Spin, Button, Typography } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import { postLoginData } from "../../redux/Slice/AuthSlice";
import "./login.scss";

const { Title, Text } = Typography;

const SignIn = () => {
	const navigate = useNavigate();
	const { loading, user } = useSelector((state) => state.auth);

	const dispatch = useDispatch();

	const login = (e) => {
		const data = { ...e };
		dispatch(postLoginData({ data }));
		if (localStorage.getItem("isModalShown") === null) {
			localStorage.setItem("isModalShown", true);
		}
	};

	const antIcon = (
		<LoadingOutlined
			style={{
				fontSize: 24,
			}}
			spin
		/>
	);

	return (
		<section
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
				backgroundColor: "#f0f2f5",
			}}
			className="login-container"
		>
			<Card
				className="cont new-cls-cont"
				style={{
					width: 400,
					padding: "20px",
					borderRadius: "10px",
					boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
				}}
			>
				<Title
					level={2}
					className="signin_heading"
					style={{ textAlign: "center", marginBottom: "20px" }}
				>
					Login
				</Title>
				<div className="form sign-in">
					<Form
						onFinish={login}
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						autoComplete="off"
						className="gx-signin-form gx-form-row0"
					>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									required: true,
									message: "Email is required",
								},
								{
									type: "email",
									message: "The input is not valid E-mail!",
								},
							]}
						>
							<Input placeholder="Enter your email" />
						</Form.Item>
						<Form.Item
							label="Password"
							name="password"
							rules={[
								{
									required: true,
									message: "Password is required",
								},
							]}
						>
							<Input.Password placeholder="Enter your password" />
						</Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="submit"
							disabled={loading}
							block
						>
							Login{" "}
							{loading ? <Spin indicator={antIcon} className="pl-2" /> : ""}
						</Button>
					</Form>
					<Text
						style={{ display: "block", textAlign: "center", marginTop: "20px" }}
					>
						Don't have an account? <Link to="/sign-up">Register</Link>
					</Text>
				</div>
			</Card>
		</section>
	);
};

export default SignIn;
